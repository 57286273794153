import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * Combines multiple class names into a single string.
 *
 * @param {...ClassValue[]} inputs - The class names to combine.
 * @returns {string} The combined class names.
 */
export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs))
}

/**
 * Type definition for a debounced function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DebouncedFunction<T extends (...args: any[]) => void> = (
  ...args: Parameters<T>
) => void

/**
 * Creates a debounced function that delays invoking the provided function until
 * after the specified wait time has elapsed since the last time the debounced
 * function was invoked.
 *
 * @param {T} func - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {DebouncedFunction<T>} The debounced function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any[]) => void>(
  func: T,
  wait: number,
): DebouncedFunction<T> => {
  let timeout: NodeJS.Timeout
  return (...args: Parameters<T>) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

/**
 * Formats the given number of bytes into a human-readable string representation.
 *
 * @param {number} bytes - The number of bytes to format.
 * @param {number} [decimals=2] - The number of decimal places to round to.
 * @returns {string} The formatted string representation of the bytes.
 */
export function formatBytes(bytes: number, decimals = 2): string {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ]

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Formats the given number of bits into a human-readable string representation.
 *
 * @param {number} bits - The number of bits to format.
 * @param {number} [decimals=2] - The number of decimal places to round to.
 * @returns {string} The formatted string representation of the bits.
 */
export function formatBits(bits: number, decimals = 2): string {
  if (!+bits) return '0 Bits'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bits', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bits) / Math.log(k))

  return `${parseFloat((bits / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Formats the given number of bits into a human-readable string representation.
 *
 * @param {number} bits - The number of bits to format.
 * @param {number} [decimals=2] - The number of decimal places to round to.
 * @returns {string} The formatted string representation of the bits.
 */
export function formatBitsPerSecond(bits: number, decimals = 2): string {
  if (!+bits) return '0 Bps'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = [
    'bps',
    'Kbps',
    'Mbps',
    'Gbps',
    'Tbps',
    'Pbps',
    'Ebps',
    'Zbps',
    'Ybps',
  ]

  const i = Math.floor(Math.log(bits) / Math.log(k))

  return `${parseFloat((bits / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Returns the dirty values from the given dirty fields and values.
 * Dirty values are the values that have been modified or marked as dirty in the dirty fields.
 *
 * @template DirtyFields - The type of the dirty fields.
 * @template Values - The type of the values.
 * @param dirtyFields - The dirty fields object.
 * @param values - The values object.
 * @returns The partial object of the dirty values.
 */
export function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Partial<Record<keyof DirtyFields, unknown>>,
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(
              dirtyFields[key] as DirtyFields,
              values[key] as Values,
            )
          : values[key],
    }
  }, {})

  return dirtyValues
}

/**
 * Returns whether the session should be refreshed based on the issue time and expiration time.
 *
 * @param issueTime - The time the session was issued.
 * @param expirationTime - The time the session will expire.
 * @returns boolean
 */
export function shouldRefresh(
  issueTime: number,
  expirationTime: number,
): boolean {
  const currentTime = Math.floor(Date.now() / 1000)
  const sessionLifetime = expirationTime - issueTime
  const remainingTime = expirationTime - currentTime
  const percentageRemaining = (remainingTime / sessionLifetime) * 100

  return percentageRemaining < 25
}

/**
 * Formats the time difference between two timestamps into a human-readable string.
 *
 * @param start - The start timestamp in milliseconds.
 * @param end - The end timestamp in milliseconds.
 * @returns A string representing the time difference in days, hours, minutes, and seconds.
 *
 * @example
 * ```typescript
 * const start = Date.now();
 * const end = start + 90061000; // 1 day, 1 hour, 1 minute, and 1 second later
 * console.log(formatTimeDifference(start, end)); // "1d 1h 1m 1s"
 * ```
 */
export function formatTimeDifference(start: number, end: number): string {
  const diff = Math.abs(end - start)
  const seconds = Math.floor(diff / 1000)

  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = seconds % 60

  const parts: string[] = []

  if (d > 0) parts.push(`${d}d`)
  if (h > 0) parts.push(`${h}h`)
  if (m > 0) parts.push(`${m}m`)
  if (s > 0) parts.push(`${s}s`)

  return parts.join(' ') || '0s'
}

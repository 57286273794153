import {
  GizmoHelper,
  GizmoViewport,
  OrbitControls,
  PointerLockControls,
} from '@react-three/drei'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useControls } from 'leva'
import React, { useRef, useState } from 'react'
import * as THREE from 'three'
import { PointerLockControls as PointerLockControlsImpl } from 'three-stdlib'

import { MeasurementTool } from './MeasurementTool'
import { ToolPanel } from './ToolPanel'
import { useKeyboardControls } from './useKeyboardControls'
import { useModelLoader } from './useModelLoader'

interface ModelViewerProps {
  objUrl: string
  mtlUrls?: string[]
  textureUrlMap?: { [key: string]: string }
  width?: number
  height?: number
  backgroundColor?: string
  onError?: (error: Error) => void
  onLoad?: () => void
}

const CAMERA_FOV = 75
const CAMERA_NEAR = 0.0001
const CAMERA_FAR = 10000
const CAMERA_INITIAL_POSITION: [number, number, number] = [2, 2, 2]

interface ModelProps {
  objUrl: string
  mtlUrls: string[]
  textureUrlMap: { [key: string]: string }
  onError?: (error: Error) => void
  onLoad?: () => void
}

const Model = React.memo(
  ({
    objUrl,
    mtlUrls = [],
    textureUrlMap = {},
    onError,
    onLoad,
  }: ModelProps) => {
    const modelRef = useRef<THREE.Group>(null)
    const { data, error } = useModelLoader({
      objUrl,
      mtlUrls,
      textureUrlMap,
    })

    React.useEffect(() => {
      if (error) {
        onError?.(error as Error)
      }
    }, [error, onError])

    React.useEffect(() => {
      if (data && modelRef.current) {
        modelRef.current.clear()
        modelRef.current.add(data.object.clone())
        onLoad?.()
      }
    }, [data, onLoad])

    return <group ref={modelRef} />
  },
)

const CameraControls = ({
  isFlyMode,
  speed,
}: {
  isFlyMode: boolean
  speed: number
}) => {
  const { camera } = useThree()
  const velocity = useRef(new THREE.Vector3())
  const moveState = useKeyboardControls()
  const pointerControlsRef = useRef<PointerLockControlsImpl>(null)

  const [, setControls] = useControls('Camera Position', () => ({
    x: {
      value: camera.position.x,
      step: 0.001,
      onChange: (value) => {
        camera.position.x = value
      },
    },
    y: {
      value: camera.position.y,
      step: 0.001,
      onChange: (value) => {
        camera.position.y = value
      },
    },
    z: {
      value: camera.position.z,
      step: 0.001,
      onChange: (value) => {
        camera.position.z = value
      },
    },
  }))

  useFrame(() => {
    if (isFlyMode) {
      velocity.current.set(0, 0, 0)

      const forward = new THREE.Vector3()
      const right = new THREE.Vector3()
      camera.getWorldDirection(forward)
      right.crossVectors(camera.up, forward).normalize()

      if (moveState.forward) velocity.current.addScaledVector(forward, speed)
      if (moveState.backward) velocity.current.addScaledVector(forward, -speed)
      if (moveState.left) velocity.current.addScaledVector(right, speed)
      if (moveState.right) velocity.current.addScaledVector(right, -speed)
      if (moveState.up) velocity.current.y += speed
      if (moveState.down) velocity.current.y -= speed

      camera.position.add(velocity.current)
    }

    setControls({
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    })
  })

  React.useEffect(() => {
    const controls = pointerControlsRef.current
    if (!controls) return

    if (isFlyMode && !document.pointerLockElement) {
      controls.lock()
    } else if (!isFlyMode && document.pointerLockElement) {
      controls.unlock()
    }
  }, [isFlyMode])

  return isFlyMode ? (
    <PointerLockControls ref={pointerControlsRef} makeDefault />
  ) : (
    <OrbitControls
      makeDefault
      maxDistance={1000}
      minDistance={0.001}
      enablePan
      enableZoom
      dampingFactor={0.05}
      panSpeed={1.5}
      zoomSpeed={1.2}
    />
  )
}

const ModelViewer: React.FC<ModelViewerProps> = (props) => {
  const [isFlyMode, setIsFlyMode] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [activeTool, setActiveTool] = useState<string>('none')
  const containerRef = useRef<HTMLDivElement>(null)

  const { speed, color } = useControls('Settings', {
    color: {
      value: props.backgroundColor || '#00bfff',
      label: 'Background Color',
    },
    speed: {
      value: 5,
      min: 1,
      max: 10,
      step: 1,
    },
  })

  const modelProps = React.useMemo(
    () => ({
      objUrl: props.objUrl,
      mtlUrls: props.mtlUrls || [],
      textureUrlMap: props.textureUrlMap || {},
      onError: (err: Error) => setError(err.message),
      onLoad: props.onLoad,
    }),
    [props.objUrl, props.mtlUrls, props.textureUrlMap, props.onLoad],
  )

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'KeyV') {
        setIsFlyMode((prev) => !prev)
      }
    }

    const handlePointerLockChange = () => {
      if (!document.pointerLockElement) {
        setIsFlyMode(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('pointerlockchange', handlePointerLockChange)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('pointerlockchange', handlePointerLockChange)
    }
  }, [])

  const { isLoading } = useModelLoader({
    objUrl: props.objUrl,
    mtlUrls: props.mtlUrls,
    textureUrlMap: props.textureUrlMap,
  })

  return (
    <div
      ref={containerRef}
      className="relative w-full h-full"
      style={{ width: props.width || '100%', height: props.height || '100%' }}
    >
      <Canvas
        camera={{
          fov: CAMERA_FOV,
          near: CAMERA_NEAR,
          far: CAMERA_FAR,
          position: CAMERA_INITIAL_POSITION,
        }}
        gl={{ antialias: true }}
        style={{ background: color }}
      >
        <ambientLight intensity={0.8} />
        <directionalLight position={[5, 5, 5]} intensity={1} castShadow />
        <hemisphereLight
          intensity={1}
          groundColor={new THREE.Color(0x080820)}
        />

        <CameraControls isFlyMode={isFlyMode} speed={speed * 0.001} />
        <Model {...modelProps} />
        <MeasurementTool active={activeTool === 'measure'} />
        <GizmoHelper alignment="bottom-right" margin={[80, 80]}>
          <GizmoViewport />
        </GizmoHelper>
      </Canvas>

      <ToolPanel activeTool={activeTool} onToolSelect={setActiveTool} />

      {isLoading && !error && (
        <div className="absolute inset-0 flex items-center justify-center bg-background">
          <div className="text-center text-content">
            <div className="mb-2">Loading Model...</div>
            <div>Please wait...</div>
          </div>
        </div>
      )}

      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-red-50 bg-opacity-75">
          <div className="text-red-600 text-center p-4">{error}</div>
        </div>
      )}

      <div className="absolute bottom-4 left-4 text-sm text-white bg-black bg-opacity-50 p-2 rounded">
        {activeTool === 'measure' ? (
          <>
            Click two points to measure distance
            <br />
          </>
        ) : (
          <>
            Press V to toggle fly mode
            <br />
            {isFlyMode ? (
              <>
                WASD: Move
                <br />
                Space/Shift: Up/Down
                <br />
                Mouse: Look
              </>
            ) : (
              'Orbit Controls Active'
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ModelViewer

import axios, { AxiosProgressEvent } from 'axios'

/**
 * Callback function type for upload progress.
 * @param progress - The progress of the upload, represented as a number between 0 and 100.
 * @param rate - The rate of the upload, represented as the number of bytes per second.
 */
type UploadProgressCallback = (
  progress: number,
  rate: number,
  status: string,
) => void

/**
 * Uploads a file with progress tracking.
 *
 * @param url - The URL to upload the file to.
 * @param file - The file to be uploaded.
 * @param onProgress - A callback function to track the upload progress.
 *                     It receives the progress percentage and upload rate as parameters.
 * @returns A Promise that resolves when the file upload is complete, or rejects with an error.
 */
const uploadFileWithProgress = async (
  url: string,
  file: File,
  onProgress: UploadProgressCallback,
) => {
  const fileData = await file.arrayBuffer()
  const requestData = new Uint8Array(fileData)

  // if (file.name.startsWith('DJI_001')) {
  //   onProgress(0, 0, 'error')
  //   throw new Error(`Fake error for testing - erroring on ${file.name}`)
  // }

  return await axios
    .put(url, requestData, {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':
          'Authorization,Content-Type,Access-Control-Allow-Origin',
      },
      onUploadProgress: (event: AxiosProgressEvent) => {
        if (event.total != null) {
          const progress = (event.loaded / event.total) * 100
          onProgress(
            progress,
            event.rate || 0,
            progress === 100 ? 'completed' : 'uploading',
          )
        }
      },
    })
    .catch((error) => {
      onProgress(0, 0, 'error')
      throw error
    })
}

export default uploadFileWithProgress

import { FC } from 'react'

import { Button } from '../Button'
import Modal from '../Modal'

interface ConfirmationDialogProps {
  title: string
  description: string
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  confirmText?: string
  cancelText?: string
  variant?: 'destructive' | 'default'
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  variant = 'default',
}) => {
  return (
    <Modal
      title={title}
      description={description}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          onClick={onConfirm}
          variant={variant === 'destructive' ? 'destructive' : 'default'}
          className="w-full sm:ml-3 sm:w-auto"
        >
          {confirmText}
        </Button>
        <Button
          onClick={onClose}
          variant="outline"
          className="mt-3 w-full sm:mt-0 sm:w-auto"
        >
          {cancelText}
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmationDialog

import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import { cn } from '../../lib/utils'
import { Button, ButtonProps } from '../Button'

type FlyoutMenuProps = {
  buttonClassName?: string
  buttonVariant?: ButtonProps['variant']
  buttonIcon?: React.ReactNode
  autoCloseDelay?: number
}

/**
 * FlyoutMenu component renders a button that toggles a flyout menu with actions.
 * The menu automatically positions itself based on the available space in the viewport.
 * It also auto-closes after a specified delay unless the user is hovering over the button or menu.
 *
 * ```
 * @component
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The content to be displayed in the menu
 * @param {number} [props.autoCloseDelay=1000] - The delay in milliseconds before the menu auto-closes
 *
 * @returns {JSX.Element} The rendered FlyoutMenu component
 *
 * @example
 *
 * <FlyoutMenu autoCloseDelay={3000}>
 *   <div>Action 1</div>
 * </FlyoutMenu>
 * ```
 */
const FlyoutMenu = ({
  autoCloseDelay = 1000,
  buttonClassName,
  buttonVariant = 'ghost',
  buttonIcon,
  children,
}: PropsWithChildren<FlyoutMenuProps>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [menuPosition, setMenuPosition] = useState({
    top: 0,
    left: 0,
  })

  const menuRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  /**
   * Helper function to clear the auto-close timer
   *
   * @callback clearAutoCloseTimer
   * @returns {void}
   */
  const clearAutoCloseTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }, [])

  /**
   * Updates the position of the menu based on the button's position and the viewport dimensions.
   * Ensures the menu is always visible both vertically and horizontally within the viewport.
   *
   * @callback updateMenuPosition
   * @returns {void}
   */
  const updateMenuPosition = useCallback(() => {
    if (!isOpen || !buttonRef.current) return

    const buttonRect = buttonRef.current.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    const viewportWidth = window.innerWidth
    const menuHeight = menuRef.current?.offsetHeight || 100
    const menuWidth = menuRef.current?.offsetWidth || 150

    // Determine vertical position
    const top =
      viewportHeight - buttonRect.bottom < menuHeight &&
      buttonRect.top > menuHeight
        ? buttonRect.top - menuHeight - 8
        : buttonRect.bottom + 8

    // Determine horizontal position
    let left = buttonRect.right - menuWidth
    if (left < 12) left = buttonRect.left
    if (left + menuWidth > viewportWidth - 12)
      left = buttonRect.right - menuWidth

    setMenuPosition({ top, left })
  }, [isOpen])

  /**
   * Handles clicks outside the menu and button to close the menu
   *
   * @callback handleClickOutside
   * @param {MouseEvent} event - The mouse event
   * @returns {void}
   */
  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      !menuRef.current?.contains(event.target as Node) &&
      !buttonRef.current?.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }, [])

  /**
   * Handles clicks on menu items to close the menu
   *
   * @callback handleMenuClick
   * @param {React.MouseEvent} event - The mouse event
   * @returns {void}
   */
  const handleMenuClick = useCallback((event: React.MouseEvent) => {
    // If the click target is a child element (not the menu container itself)
    if (event.target !== event.currentTarget) {
      setIsOpen(false)
    }
  }, [])

  const handleViewportChange = () => setIsOpen(false)

  useEffect(() => {
    if (!isOpen) return

    clearAutoCloseTimer()

    if (isHovering) return

    timerRef.current = setTimeout(() => {
      setIsOpen(false)
    }, autoCloseDelay)

    return clearAutoCloseTimer
  }, [isOpen, isHovering, autoCloseDelay, clearAutoCloseTimer])

  useEffect(() => {
    if (!isOpen) return

    updateMenuPosition()

    window.addEventListener('scroll', handleViewportChange, true)
    window.addEventListener('resize', handleViewportChange)

    return () => {
      window.removeEventListener('scroll', handleViewportChange, true)
      window.removeEventListener('resize', handleViewportChange)
    }
  }, [isOpen, updateMenuPosition])

  useEffect(() => {
    if (!isOpen) return

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isOpen, handleClickOutside])

  return (
    <div className="relative">
      <Button
        className={cn('text-secondary hover:text-on-surface', buttonClassName)}
        variant={buttonVariant}
        size="icon"
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        onMouseEnter={() => setIsHovering(true)} // Set hovering state when entering button
        onMouseLeave={() => setIsHovering(false)} // Clear hovering state when leaving button
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        {buttonIcon || (
          <EllipsisVerticalIcon className="h-5 w-5 stroke-current" />
        )}
      </Button>

      {isOpen &&
        createPortal(
          <div
            ref={menuRef}
            className="fixed z-50 rounded-lg border border-accent bg-surface shadow-lg"
            style={{
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
            }}
            role="menu"
            aria-orientation="vertical"
            onMouseEnter={() => setIsHovering(true)} // Set hovering state when entering menu
            onMouseLeave={() => setIsHovering(false)} // Clear hovering state when leaving menu
          >
            <div
              className="p-2 space-y-2 w-full text-on-surface flex flex-col"
              onClick={handleMenuClick} // Close menu when a child item is clicked
            >
              {React.Children.map(children, (child, index) => (
                <>
                  <div className="w-full">{child}</div>
                  {/* Add a divider between items */}
                  {index < React.Children.count(children) - 1 && (
                    <hr className="border-t border-accent my-1 w-full" />
                  )}
                </>
              ))}
            </div>
          </div>,
          document.body, // Portal to body to avoid clipping issues
        )}
    </div>
  )
}

export default FlyoutMenu

import { fitBounds } from '@math.gl/web-mercator'
import { ViewState } from 'react-map-gl/maplibre'

/**
 * We want the map to be centered on the COG and zoomed out to fit the COG in the viewport
 *
 * Calculating the center of the COG is easy, working out the zoom level to fit the COG
 * in the viewport is a bit more involved.
 *
 * We are using the `fitBounds` function from the `@math.gl/web-mercator` package (part
 * of the `vis.gl` suite of libraries) to calculate the zoom level. This library is
 * designed to work with react-map-gl which is the react library we are using to wrap
 * maplibre-gl.
 */
export const createInitialViewState = (
  viewRect: {
    width: number
    height: number
  },
  bounds: [number, number, number, number],
  padding?: number,
): Partial<ViewState> => {
  return fitBounds({
    width: viewRect.width,
    height: viewRect.height,
    bounds: [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ],
    padding,
  })
}

/**
 * Creates an initial view state that is synchronized with the given view rectangle and bounds.
 *
 * @param viewRect - An object containing the width and height of the view rectangle.
 * @param bounds - An object containing two sets of bounds (a and b), each represented by an array of four numbers.
 * @param padding - An optional padding value to be applied to the view state.
 * @returns A partial view state object.
 */
export const createSyncedInitialViewState = (
  viewRect: {
    width: number
    height: number
  },
  bounds: {
    a: [number, number, number, number]
    b: [number, number, number, number]
  },
  padding?: number,
): Partial<ViewState> => {
  const combinedBounds: [number, number, number, number] = [
    Math.min(bounds.a[0], bounds.b[0]),
    Math.min(bounds.a[1], bounds.b[1]),
    Math.max(bounds.a[2], bounds.b[2]),
    Math.max(bounds.a[3], bounds.b[3]),
  ]

  return createInitialViewState(viewRect, combinedBounds, padding)
}

import React, { useCallback, useEffect, useRef, useState } from 'react'

import { FileWithProgress } from '../../hooks/useAssetUpload'
import { formatBits, formatBitsPerSecond } from '../../lib/utils'

type MultiFileUploadProgressProps = {
  filesWithProgress: FileWithProgress[]
}

type RateHistory = {
  timestamp: number
  rate: number
}

const MultiFileUploadProgress: React.FC<MultiFileUploadProgressProps> = ({
  filesWithProgress,
}) => {
  const [startTime] = useState<number>(Date.now())
  const [finalTime, setFinalTime] = useState<number | null>(null)
  const [displayRate, setDisplayRate] = useState<number>(0)
  const [displayTimeRemaining, setDisplayTimeRemaining] = useState<
    number | null
  >(null)
  const rateHistory = useRef<RateHistory[]>([])

  // Calculate totals
  const totalFiles = filesWithProgress.length
  const totalSize = filesWithProgress.reduce((acc, f) => acc + f.file.size, 0)

  // Calculate current progress
  const completedFiles = filesWithProgress.filter(
    (f) => f.status === 'completed' || f.status === 'error',
  ).length
  const errorFiles = filesWithProgress.filter(
    (f) => f.status === 'error',
  ).length
  const calculateTransferredSize = () => {
    return filesWithProgress.reduce((acc, f) => {
      if (f.status === 'completed') return acc + f.file.size
      if (f.status === 'uploading')
        return acc + (f.file.size * f.progress) / 100
      return acc
    }, 0)
  }

  const calculateErrorSize = () => {
    return filesWithProgress.reduce((acc, f) => {
      if (f.status === 'error') return acc + f.file.size
      return acc
    }, 0)
  }

  const transferredSize = calculateTransferredSize()
  const errorSize = calculateErrorSize()
  const currentProgress = (transferredSize / totalSize) * 100
  const errorProgress = (errorSize / totalSize) * 100

  const formatElapsedTime = (ms: number) => {
    const seconds = Math.floor(ms / 1000)
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
  }

  const calculateFinalTransferRate = useCallback(() => {
    if (!finalTime || finalTime === 0) return 0
    const seconds = finalTime / 1000
    return totalSize / seconds
  }, [finalTime, totalSize])

  // Set the final time when all files are completed.
  useEffect(() => {
    const totalFiles = filesWithProgress.length
    const completedFiles = filesWithProgress.filter(
      (f) => f.status === 'completed' || f.status === 'error',
    ).length

    if (completedFiles === totalFiles && totalFiles > 0 && finalTime === null) {
      setFinalTime(Date.now() - startTime)
    }
  }, [filesWithProgress, startTime, finalTime])

  // Store transfer rate history for last 10 seconds. The displayed rate
  // can then be calculated as the average of the history, smoothing out
  // the display.
  const updateRateHistory = () => {
    const uploadingFiles = filesWithProgress.filter(
      (f) => f.status === 'uploading',
    )
    const currentRate = uploadingFiles.reduce((acc, f) => acc + f.rate, 0)

    // Add new values to history
    const now = Date.now()
    let newHistory = [
      ...rateHistory.current,
      { timestamp: now, rate: currentRate },
    ]
    newHistory = newHistory.filter((h) => now - h.timestamp <= 10000) // Keep last 10 seconds
    rateHistory.current = newHistory
  }

  // Run updateRateHistory every 100 ms
  useEffect(() => {
    const interval = setInterval(updateRateHistory, 1000)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateAvgRateAndTimeRemaining = () => {
    // Calculate averages
    const avgRate =
      rateHistory.current.reduce((acc, h) => acc + h.rate, 0) /
      rateHistory.current.length
    const transferredSize = calculateTransferredSize()
    const remainingBits = totalSize - transferredSize
    const timeRemaining = avgRate > 0 ? (remainingBits / avgRate) * 1000 : null

    setDisplayRate(avgRate)
    setDisplayTimeRemaining(timeRemaining)
  }

  // Run updateAvgRateAndTimeRemaining every 1 seconds
  useEffect(() => {
    // Then run every 1 seconds
    const interval = setInterval(() => {
      updateAvgRateAndTimeRemaining()
    }, 1000)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Stop the avg rate and time remaining from being updated when all files are completed
  useEffect(() => {
    if (completedFiles === totalFiles && totalFiles > 0) {
      rateHistory.current = []
      setDisplayRate(0)
      setDisplayTimeRemaining(null)
    }
  }, [completedFiles, totalFiles])

  if (totalFiles === 0) {
    return null
  }

  return (
    <div className="space-y-2">
      {/* The current progress */}
      <div className="flex justify-between text-sm">
        <span>
          {completedFiles} of {totalFiles} files completed
          {errorFiles > 0 && (
            <span className="text-status-error"> ( {errorFiles} errors )</span>
          )}
        </span>
        {completedFiles < totalFiles && (
          <span>
            {formatBitsPerSecond(displayRate)}
            <span className="ml-2">
              • Estimated time remaining{' '}
              {formatElapsedTime(displayTimeRemaining || 0)}
            </span>
          </span>
        )}
      </div>
      {/* The progress bar */}
      <div className="relative h-2 w-full rounded-full">
        <div
          className="absolute left-0 top-0 h-full rounded-l-full bg-status-running transition-all duration-300"
          style={{ width: `${currentProgress}%` }}
        />
        <div
          className="absolute left-0 top-0 h-full rounded-r-full bg-status-error transition-all duration-300"
          style={{ width: `${errorProgress}%`, left: `${currentProgress}%` }}
        />
      </div>
      {/* The upload complete banner */}
      {completedFiles === totalFiles &&
        totalFiles > 0 &&
        finalTime !== null && (
          <div className="flex flex-col items-center space-y-1 text-center">
            <div>
              {errorFiles === 0
                ? 'Upload Complete'
                : errorFiles === totalFiles
                  ? 'Upload Failed'
                  : 'Upload Complete with Errors'}
            </div>
            <div>
              {totalFiles} files • {formatBits(totalSize)}
            </div>
            <div>
              {errorFiles > 0 && (
                <span>
                  <span className="text-status-success">
                    {totalFiles - errorFiles} files completed •{' '}
                  </span>
                  <span className="text-status-error">
                    {' '}
                    {errorFiles} files failed{' '}
                  </span>
                </span>
              )}
            </div>
            <div>Total time: {formatElapsedTime(finalTime)}</div>
            <div>
              Average speed: {formatBitsPerSecond(calculateFinalTransferRate())}
            </div>
          </div>
        )}
      {/* Failed files list */}
      {errorFiles > 0 && (
        <div className="mt-4">
          <div className="font-medium text-status-error">Failed Files:</div>
          <ul className="mt-1 max-h-40 overflow-y-auto ">
            {filesWithProgress
              .filter((f) => f.status === 'error')
              .map((f, index) => (
                <li key={index} className="truncate">
                  {f.file.name}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default MultiFileUploadProgress

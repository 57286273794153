import React from 'react'

interface ToolPanelProps {
  activeTool: string
  onToolSelect: (tool: string) => void
}

export const ToolPanel: React.FC<ToolPanelProps> = ({
  activeTool,
  onToolSelect,
}) => {
  return (
    <div className="absolute top-2 left-2 bg-black bg-opacity-50 p-2 rounded text-white">
      <button
        className={`block w-full p-2 mb-2 rounded ${
          activeTool === 'measure' ? 'bg-blue-500' : 'bg-gray-700'
        }`}
        onClick={() => onToolSelect('measure')}
      >
        <span role="img" aria-label="Measure">
          📏
        </span>{' '}
        Measure
      </button>
      <button
        className={`block w-full p-2 rounded ${
          activeTool === 'none' ? 'bg-blue-500' : 'bg-gray-700'
        }`}
        onClick={() => onToolSelect('none')}
      >
        <span role="img" aria-label="Clear Tools">
          ❌
        </span>{' '}
        Clear Tools
      </button>
    </div>
  )
}

import { QueryParameterBag } from '@smithy/types'

import {
  DroidMapMetadata,
  Paged,
  ServiceClient,
  ServiceClientContext,
  queryParameterBagFromPaginationControls,
} from '@droidmap/shared/service-contract'

import {
  Asset,
  AssetSet,
  AssetWithPresignedUrl,
  CreateAssetInput,
  CreateAssetSetInput,
  DeleteAssetInput,
  DeleteAssetSetInput,
  GetAssetByPathInput,
  GetAssetInput,
  GetAssetSetInput,
  ListAssetSetsByProjectInput,
  ListAssetsByAssetSetInput,
  UpdateAssetInput,
  UpdateAssetSetInput,
} from '../models/asset'

export class AssetServiceClient extends ServiceClient {
  public static readonly SERVICE_NAME = 'AssetService'

  constructor(input: ServiceClientContext) {
    super({ ...input, serviceName: AssetServiceClient.SERVICE_NAME })
  }

  /* -------------------------------- AssetSet -------------------------------- */

  createAssetSet = async (
    createAssetSetInput: CreateAssetSetInput,
    metadata?: DroidMapMetadata,
  ): Promise<AssetSet> => {
    const assetSet: AssetSet | undefined = await this.callApiRouteWithToken<
      CreateAssetSetInput,
      AssetSet
    >({
      method: 'POST',
      path: '/asset-sets',
      body: createAssetSetInput,
      metadata,
    })

    if (!assetSet) {
      throw new Error('Failed to create asset set')
    }

    return assetSet
  }

  getAssetSet = async (
    input: GetAssetSetInput,
    metadata?: DroidMapMetadata,
  ): Promise<AssetSet> => {
    let queryParameters: QueryParameterBag | undefined = undefined
    if (input.presignedUrlType) {
      queryParameters = {
        presignedUrlType: input.presignedUrlType,
      }
    }

    const assetSet = await this.callApiRouteWithToken<string, AssetSet>({
      method: 'GET',
      path: `/asset-sets/${input.assetSetId}`,
      queryParameters,
      metadata,
    })

    return assetSet
  }

  getAssetSetManifest = async (
    input: GetAssetSetInput,
    metadata?: DroidMapMetadata,
  ): Promise<Asset | AssetWithPresignedUrl | null> => {
    // First, get the asset set to check if it has a manifest path
    const assetSet = await this.getAssetSet(
      {
        assetSetId: input.assetSetId,
      },
      metadata,
    )

    if (!assetSet.manifestPath) {
      return null
    }

    // Use the manifest path to get the asset
    const asset = await this.getAssetByPath(
      {
        path: assetSet.manifestPath,
        assetSetId: input.assetSetId,
        presignedUrlType: input.presignedUrlType,
      },
      metadata,
    )

    return asset
  }

  updateAssetSet = async (
    updateAssetSetInput: UpdateAssetSetInput,
    metadata?: DroidMapMetadata,
  ): Promise<AssetSet> => {
    const { assetSetId } = updateAssetSetInput

    const asset = await this.callApiRouteWithToken<
      UpdateAssetSetInput,
      AssetSet
    >({
      method: 'PATCH',
      path: `/asset-sets/${assetSetId}`,
      body: updateAssetSetInput,
      metadata,
    })

    return asset
  }

  deleteAssetSet = async (
    input: DeleteAssetSetInput,
    metadata?: DroidMapMetadata,
  ): Promise<AssetSet> => {
    const assetSet = await this.callApiRouteWithToken<string, AssetSet>({
      method: 'DELETE',
      path: `/asset-sets/${input.assetSetId}`,
      metadata,
    })

    return assetSet
  }

  listAssetSetsByProject = async (
    input: ListAssetSetsByProjectInput,
    metadata?: DroidMapMetadata,
  ): Promise<Paged<AssetSet>> => {
    const { projectId, next, prev, pageSize } = input

    const queryParameters: QueryParameterBag =
      queryParameterBagFromPaginationControls({
        next,
        prev,
        pageSize,
      })

    const assetSets = await this.callApiRouteWithToken<
      ListAssetSetsByProjectInput,
      Paged<AssetSet>
    >({
      method: 'GET',
      path: `/projects/${projectId}/asset-sets`,
      queryParameters,
      metadata,
    })

    return assetSets
  }

  /* ---------------------------------- Asset --------------------------------- */

  createAsset = async (
    createAssetInput: CreateAssetInput,
    metadata?: DroidMapMetadata,
  ): Promise<AssetWithPresignedUrl> => {
    const asset: AssetWithPresignedUrl | undefined =
      await this.callApiRouteWithToken<CreateAssetInput, AssetWithPresignedUrl>(
        {
          method: 'POST',
          path: `/assets`,
          body: createAssetInput,
          metadata,
        },
      )

    if (!asset) {
      throw new Error('Failed to create asset')
    }

    return asset
  }

  getAsset = async (
    input: GetAssetInput,
    metadata?: DroidMapMetadata,
  ): Promise<Asset | AssetWithPresignedUrl> => {
    let queryParameters: QueryParameterBag | undefined = undefined
    if (input.presignedUrlType) {
      queryParameters = {
        presignedUrlType: input.presignedUrlType,
      }
    }

    const asset = await this.callApiRouteWithToken<
      string,
      Asset | AssetWithPresignedUrl
    >({
      method: 'GET',
      path: `/assets/${input.assetId}`,
      queryParameters,
      metadata,
    })

    return asset
  }

  getAssetByPath = async (
    input: GetAssetByPathInput,
    metadata?: DroidMapMetadata,
  ): Promise<Asset | AssetWithPresignedUrl> => {
    let queryParameters: QueryParameterBag | undefined = undefined
    if (input.presignedUrlType) {
      queryParameters = {
        presignedUrlType: input.presignedUrlType,
      }
    }

    const asset = await this.callApiRouteWithToken<
      string,
      Asset | AssetWithPresignedUrl
    >({
      method: 'GET',
      path: `/assets/path/${input.assetSetId}/${input.path}`,
      queryParameters,
      metadata,
    })
    return asset
  }

  updateAsset = async (
    updateAssetInput: UpdateAssetInput,
    metadata?: DroidMapMetadata,
  ): Promise<AssetWithPresignedUrl> => {
    const { assetId } = updateAssetInput

    const asset = await this.callApiRouteWithToken<
      UpdateAssetInput,
      AssetWithPresignedUrl
    >({
      method: 'PATCH',
      path: `/assets/${assetId}`,
      body: updateAssetInput,
      metadata,
    })

    return asset
  }

  deleteAsset = async (
    input: DeleteAssetInput,
    metadata?: DroidMapMetadata,
  ): Promise<Asset> => {
    const asset = await this.callApiRouteWithToken<string, Asset>({
      method: 'DELETE',
      path: `/assets/${input.assetId}`,
      metadata,
    })

    return asset
  }

  listAssetsByAssetSet = async (
    listAssetsByAssetSetInput: ListAssetsByAssetSetInput,
    metadata?: DroidMapMetadata,
  ): Promise<Paged<AssetWithPresignedUrl>> => {
    const { assetSetId, presignedUrlType, next, prev, pageSize } =
      listAssetsByAssetSetInput

    let queryParameters: QueryParameterBag =
      queryParameterBagFromPaginationControls({
        next,
        prev,
        pageSize,
      })

    queryParameters = {
      ...queryParameters,
      ...(presignedUrlType && { presignedUrlType }),
    }

    const assets = await this.callApiRouteWithToken<
      ListAssetsByAssetSetInput,
      Paged<AssetWithPresignedUrl>
    >({
      method: 'GET',
      path: `/asset-sets/${assetSetId}/assets`,
      queryParameters,
      metadata,
    })

    if (!assets) {
      throw new Error('Failed to list assets')
    }

    return assets
  }
}

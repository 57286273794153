import { useQuery } from '@tanstack/react-query'
import { RequestTransformFunction } from 'maplibre-gl'
import { FC, useCallback } from 'react'
import {
  AttributionControl,
  Layer,
  NavigationControl,
  RasterLayer,
  RasterSource,
  Map as ReactMapGL,
  Source,
  ViewState,
  ViewStateChangeEvent,
} from 'react-map-gl/maplibre'

import { useClient } from '../../context/ClientContext'
import { useRect } from '../../hooks/useRect'
import Spinner from '../Spinner'
import { BasemapStyle, createBaseMapStyle } from './basemap'
import { createInitialViewState } from './util'

export type MapProps = {
  assetId: string
  initialViewState?: Partial<ViewState>
  onMove?: (viewState: ViewStateChangeEvent) => void
} & Partial<ViewState>

export const Map: FC<MapProps> = ({
  assetId,
  onMove,
  initialViewState,
  ...viewState
}: MapProps) => {
  const [divRect, divRef] = useRect()
  const { tileClient } = useClient()

  // Get information about the Cloud Optimized GeoTIFF we are displaying
  const cogInfoQuery = useQuery({
    queryKey: ['cogInfo', assetId],
    queryFn: () => {
      return tileClient.getCogInfo({ assetId })
    },
  })

  const tokenQuery = useQuery({
    queryKey: ['tileToken'],
    queryFn: () => tileClient.getToken(),
  })

  // Returns a function that is called every time the map library is making
  // a request. We are using this hook to add the tile service api access
  // token and the necessary CORS headers to each request for a tile.
  const transformRequest = useCallback<RequestTransformFunction>(
    (url, resourceType) => {
      if (resourceType === 'Tile' && url.startsWith(tileClient.endpoint)) {
        const token = tokenQuery.data
        if (!token) {
          throw new Error('No token available')
        }
        const metadata = tileClient.getDroidMapMetadata()
        if (!metadata) {
          throw new Error('No DroidMapMetadata available')
        }
        return {
          url,
          headers: {
            ...tileClient.buildRequestHeaders(token, metadata),
          },
        }
      }
      return { url }
    },
    [tokenQuery.data, tileClient],
  )

  // We need information about the COG and the size of the div we are rendering
  // the map into before we can render the map.
  if (cogInfoQuery.isLoading || tokenQuery.isLoading || !divRect) {
    return (
      <div ref={divRef} className="h-full">
        <Spinner size="page" className="text-primary" />
      </div>
    )
  }

  // Check our cog info query completed successfully
  if (cogInfoQuery.isError) {
    return <div>Error {cogInfoQuery.error.message}</div>
  }
  if (!cogInfoQuery.data) {
    return <div>Unable to fetch image metadata.</div>
  }
  const cogInfo = cogInfoQuery.data

  // Check if token query completed successfully
  if (tokenQuery.isError) {
    return <div>Error fetching token: {tokenQuery.error.message}</div>
  }

  // Create the base map style
  const baseMapStyle = createBaseMapStyle(BasemapStyle.Imagery)

  // The raster source and layer properties for the COG
  const assetSource: RasterSource = {
    type: 'raster',
    tiles: [`${tileClient.endpoint}/cog/assets/${assetId}/tiles/{x}/{y}/{z}`],
    tileSize: 256,
    bounds: cogInfo.geographic_bounds,
  }
  const assetLayer: RasterLayer = {
    id: 'assetLayer',
    type: 'raster',
    source: 'cog',
  }
  // Center the map on the COG and zoom out to fit the COG in the viewport
  // The last number is the padding around the COG in pixels
  const mapInitialViewState =
    initialViewState ||
    createInitialViewState(divRect, cogInfo.geographic_bounds, 40)

  return (
    <div ref={divRef} className="h-full">
      <ReactMapGL
        initialViewState={mapInitialViewState}
        mapStyle={baseMapStyle}
        transformRequest={transformRequest}
        attributionControl={false}
        onMove={onMove}
        {...viewState}
      >
        <Source id="cog" {...assetSource}>
          <Layer {...assetLayer} />
        </Source>
        <NavigationControl />
        <AttributionControl
          compact={true}
          style={{
            backgroundColor: 'rgba(var(--color-surface)',
            color: 'rgba(var(--color-on-surface)',
          }}
        />
      </ReactMapGL>
    </div>
  )
}

export default Map

import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useParams } from 'react-router-dom'

import {
  AssetServiceClient,
  AssetWithPresignedUrl,
  PresignedUrlType,
} from '@droidmap/asset-service-contract'

import ModelViewer from '../../components/Models'
import Spinner from '../../components/Spinner'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { ThemeContext } from '../../context/ThemeContext'

interface TextureMap {
  [key: string]: string
}

// Constants
const PAGE_SIZE = 100
const MODEL_FOLDER = 'odm_texturing'
const SUPPORTED_EXTENSIONS = {
  MODEL: '.obj',
  MATERIAL: '.mtl',
  TEXTURE: '.png',
} as const

// Helper functions
const fetchAssets = async (
  assetClient: AssetServiceClient,
  assetSetId: string,
  next?: object,
): Promise<AssetWithPresignedUrl[]> => {
  try {
    const response = await assetClient.listAssetsByAssetSet({
      assetSetId,
      presignedUrlType: PresignedUrlType.GET,
      pageSize: PAGE_SIZE,
      next,
    })

    if (!response) {
      throw new Error('No response received from asset service')
    }

    const assets = response.items
    if (response.next) {
      const nextPageAssets = await fetchAssets(
        assetClient,
        assetSetId,
        response.next,
      )
      return [...assets, ...nextPageAssets]
    }

    return assets
  } catch (error) {
    throw new Error(
      `Failed to fetch assets: ${error instanceof Error ? error.message : 'Unknown error'}`,
    )
  }
}

const filterModelFiles = (assets: AssetWithPresignedUrl[]) => {
  return assets.filter((asset) => asset.folderPath === MODEL_FOLDER)
}

const createTextureMap = (modelFiles: AssetWithPresignedUrl[]): TextureMap => {
  return modelFiles.reduce((map, file) => {
    if (file.name.endsWith(SUPPORTED_EXTENSIONS.TEXTURE)) {
      map[file.name] = file.presignedUrl
    }
    return map
  }, {} as TextureMap)
}

const findObjFile = (modelFiles: AssetWithPresignedUrl[]) => {
  return modelFiles.find((file) =>
    file.name.endsWith(SUPPORTED_EXTENSIONS.MODEL),
  )
}

const getMtlUrls = (modelFiles: AssetWithPresignedUrl[]) => {
  return modelFiles
    .filter((file) => file.name.endsWith(SUPPORTED_EXTENSIONS.MATERIAL))
    .map((file) => file.presignedUrl)
}

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: () => void
}) => (
  <div className="p-4 border border-red-500 rounded">
    <h2 className="text-xl font-bold text-red-500">Something went wrong:</h2>
    <pre className="mt-2 text-sm">{error.message}</pre>
    <button
      onClick={resetErrorBoundary}
      className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
    >
      Try again
    </button>
  </div>
)

export default function Models() {
  const { assetSetId: id } = useParams<{ assetSetId: string }>()
  const auth = useAuth()
  const { assetClient } = useClient()
  const { theme } = useContext(ThemeContext)

  if (!id) {
    throw new Error('No asset set ID provided')
  }

  const authQuery = useQuery({
    queryKey: ['authAccessToken', auth],
    queryFn: () => auth.getAccessToken(),
    retry: 2,
    staleTime: 5 * 60 * 1000,
  })

  const assetsQuery = useQuery({
    queryKey: ['assets', id, assetClient],
    queryFn: () => fetchAssets(assetClient, id),
    enabled: !!authQuery.data, // Only fetch assets after authentication
    retry: 3,
  })

  // Loading states
  if (authQuery.isLoading || assetsQuery.isLoading) {
    return <Spinner size="page" className="text-primary" />
  }

  // Error states
  if (authQuery.isError) {
    return (
      <ErrorFallback
        error={authQuery.error as Error}
        resetErrorBoundary={authQuery.refetch}
      />
    )
  }

  if (assetsQuery.isError) {
    return (
      <ErrorFallback
        error={assetsQuery.error as Error}
        resetErrorBoundary={assetsQuery.refetch}
      />
    )
  }

  // Data validation
  if (!authQuery.data) {
    return <div className="p-4">No access token available</div>
  }

  if (!assetsQuery.data) {
    return <div className="p-4">No assets found</div>
  }

  // Process model files
  const modelFiles = filterModelFiles(assetsQuery.data)
  const textureMap = createTextureMap(modelFiles)
  const objFile = findObjFile(modelFiles)
  const mtlUrls = getMtlUrls(modelFiles)

  if (!objFile) {
    return <div className="p-4">Model file not found</div>
  }

  // Event handlers
  const handleError = (error: Error) => {
    console.error('Failed to load model:', error)
  }

  const handleLoad = () => {
    console.log('Model loaded successfully')
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ModelViewer
          objUrl={objFile.presignedUrl}
          mtlUrls={mtlUrls}
          textureUrlMap={textureMap}
          backgroundColor={theme === 'dark' ? '#09090b' : '#f4f4f5'}
          onError={handleError}
          onLoad={handleLoad}
        />
      </ErrorBoundary>
    </>
  )
}

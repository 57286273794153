/**
 * Converts a kebab-cased string to PascalCase.
 * i.e hello-world -> HelloWorld
 *
 * @param s The string to convert in kebab-case.
 * @returns The converted string.
 */
export const kebabToPascal = (s: string): string => {
  const words = s.split('-')
  const capitalizedWords = words.map((word) => capitalize(word))
  const pascal = capitalizedWords.join('')
  return pascal
}

/**
 * Converts a kebab-cased string to camelCase.
 * i.e hello-world -> helloWorld
 *
 * @param s The string to convert in kebab-case.
 * @returns The converted string.
 */
export const kebabToCamel = (s: string): string => {
  if (!s.includes('-')) {
    return s
  }

  const [first, ...words] = s.split('-')
  const capitalizedWords = words.map((word) => capitalize(word))
  const camel = [first.toLowerCase(), ...capitalizedWords].join('')
  return camel
}

/**
 * Converts a PascalCased string to kebab-case.
 * i.e HelloWorld -> hello-world
 * @param s The string to convert in PascalCase.
 * @returns The converted string.
 * @example
 * pascalToKebab('HelloWorld') // hello-world
 * pascalToKebab('HelloWorld123') // hello-world123
 */
export const pascalToKebab = (s: string): string => {
  return s.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}

/**
 * Convert a kebab cased string to snake case
 * i.e hello-world -> hello_world
 */
export const kebabToSnake = (s: string): string => {
  return s.replace(/-/g, '_')
}

/**
 * Convert a camel cased string to snake case
 * i.e helloWorld -> hello_world
 */
export const camelToSnake = (s: string): string => {
  return s.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase()
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param s The string to capitalize.
 * @returns The capitalized string.
 */
export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}
/**
 * Convert a PascalCased string to sentence case.
 * i.e HelloWorld -> Hello world
 * @param s The string to convert in PascalCase.
 * @returns The converted string.
 * @example
 * pascalToSentence('HelloWorld') // Hello world
 * pascalToSentence('helloWorld') // Hello world
 * pascalToSentence('HelloWorld123') // Hello world123
 */
export const pascalToSentence = (s: string): string => {
  return s
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .replace(/^./, (c) => c.toUpperCase())
}

/**
 * Create a cdk resource name with the region appended in PascalCase.
 *
 * @param name The name of the resource.
 * @param region The region to append to the name in kebab-case. ie. ap-southeast-2
 * @returns The resource name with the region appended in PascalCase. i.e MyVpcUsEast1
 */
export const nameWithRegion = (
  name: string,
  region: string | undefined,
): string => {
  // Why do you let region be undefined if you are just going to throw an error?
  // It simplifies the code in the calling function where you are passing in
  // the region from a cdk ```Environment``` object which allows region to
  // be optional even though in practice for how we use it, it is always defined.
  if (!region) throw new Error('Region must be defined')

  return `${capitalize(name)}${kebabToPascal(region)}`
}

/**
 * Create a cdk resource name with the account role and region appended in PascalCase.
 *
 * @param name The name of the resource.
 * @param accountRole The account role to append to the name in kebab-case. ie. frontend
 * @param region The region to append to the name in kebab-case. ie. ap-southeast-2
 * @returns The resource name with the region appended in PascalCase. i.e MyVpcUsEast1
 */
export const nameWithAccountRoleAndRegion = (
  name: string,
  accountRole: string,
  region: string | undefined,
): string => {
  // Why do you let region be undefined if you are just going to throw an error?
  // It simplifies the code in the calling function where you are passing in
  // the region from a cdk ```Environment``` object which allows region to
  // be optional even though in practice for how we use it, it is always defined.
  if (!region) throw new Error('Region must be defined')

  return `${capitalize(name)}${capitalize(accountRole)}${kebabToPascal(region)}`
}

export const nameWithEnvironment = (
  name: string,
  environment: string,
): string => {
  return `${kebabToPascal(name)}${capitalize(environment)}`
}

export const nameWithoutEnvironment = (
  name: string,
  environment: string,
): string => {
  return name.replace(capitalize(environment), '')
}

export const nameWithEnvironmentPrefix = (
  name: string,
  environment: string,
): string => {
  return `${kebabToPascal(environment)}-${capitalize(name)}`
}

export const bucketNameWithEnvironment = (
  name: string,
  environment: string,
): string => {
  return `droidmap-${environment}-${pascalToKebab(name)}`
}

export const stackNameWithEnvironment = (
  name: string,
  environment: string,
): string => {
  const stackName = `${kebabToPascal(environment)}-${capitalize(name)}Stack`

  if (validStackName(stackName)) {
    return stackName
  } else {
    throw new Error(
      `Stack name ${stackName} is invalid. Stack names must be alphanumeric, start with a letter and be between 1 and 128 characters long.`,
    )
  }
}

export const stackNameWithEnvironmentAndAccountRole = (
  name: string,
  environment: string,
  accountRole: string,
): string => {
  const stackName = `${kebabToPascal(environment)}${capitalize(
    accountRole,
  )}-${capitalize(name)}Stack`

  if (validStackName(stackName)) {
    return stackName
  } else {
    throw new Error(
      `Stack name ${stackName} is invalid. Stack names must be alphanumeric, start with a letter and be between 1 and 128 characters long.`,
    )
  }
}

export const roleNameWithEnvironment = (
  name: string,
  environment: string,
): string => {
  return `${capitalize(name)}${kebabToPascal(environment)}Role`
}

export const nameWithServiceNameAndEnvironment = (
  name: string,
  serviceName: string,
  environment: string,
): string => {
  return `${kebabToPascal(environment)}-${capitalize(serviceName)}-${name}`
}

/**
 * Remove the word 'service' from the name and any trailing '-' or '_'.
 * @param name The name to remove the word 'service' from.
 * @returns The name with the word 'service' removed and any trailing '-' or '_'.
 * @example
 * nameWithoutService('UserService') // User
 * nameWithoutService('User') // User
 * nameWithoutService('userService') // user
 * nameWithoutService('user-service') // user
 * nameWithoutService('User-Service') // User
 * nameWithoutService('user_service') // user
 * nameWithoutService('user') // user
 */
export const nameWithoutService = (name: string): string => {
  return name.replace(/service$/i, '').replace(/[-_]$/, '')
}

export const ssmParamNameWithEnvironment = (
  segments: string | string[],
  environment: string,
  serviceName?: string,
): string => {
  let segmentsJoined = ''
  if (segments instanceof Array) {
    segmentsJoined = segments.map((segment) => kebabToPascal(segment)).join('/')
  } else {
    segmentsJoined = kebabToPascal(segments)
  }
  const service = serviceName ? `${kebabToPascal(serviceName)}/` : ''
  return `/${kebabToPascal(environment)}/${service}${segmentsJoined}`
}

/**
 * A stack name can contain only alphanumeric characters (case-sensitive) and
 * hyphens. It must start with an alphabetic character and can't be longer
 * than 128 characters.
 */
const validStackName = (name: string): boolean => {
  const regex = /^[a-zA-Z][a-zA-Z0-9-]{1,127}$/
  return regex.test(name)
}

/**
 * Our intended pattern is to have a single event bus per environment. This
 * function returns the name of the event bus used in our CDK Construct for
 * a given environment.
 *
 * Given this name should be fairly static having this convenience function to
 * generate the name saves having to pass it through the cdk stack constructs
 * and lookup the name from env parameters in lambda code.
 *
 * @param {string} environmentName The name of the droidmap environment.
 * @returns {string} The name of the event bus for the given environment.
 */
export const getEventBusName = (environmentName: string): string => {
  return nameWithEnvironment('DroidMapEventBus', environmentName)
}

/**
 * Generate a queue name with the environment and the name of the queue.
 *
 * @param {string} name The name of the queue.
 * @param {string} environment The name of the droidmap environment.
 * @param {boolean} isDeadLetterQueue Whether the queue is a dead letter queue.
 * @returns {string} The name of the queue.
 */
export const queueNameWithEnvironment = (
  name: string,
  environment: string,
  isDeadLetterQueue: boolean,
): string => {
  if (isDeadLetterQueue) {
    return `dlq-${environment.toLowerCase()}-${name}`
  } else {
    return `${environment.toLowerCase()}-${name}`
  }
}

import { ulid } from 'ulid'

export enum TaskState {
  NOT_STARTED = 'not_started',
  STARTED = 'started',
  SETTLED = 'settled',
}

export enum TaskSettledResult {
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
  CANCELLED = 'cancelled',
}

export interface Task<T> {
  id: string
  create: () => CancellablePromise<T>
  state: TaskState
  settledResult?: TaskSettledResult
  result?: T
  error?: Error
}

export class TaskFactory {
  static create<T>(create: () => CancellablePromise<T>): Task<T> {
    return this.createIdentifiableTask(ulid(), create)
  }

  static createIdentifiableTask<T>(
    id: string,
    create: () => CancellablePromise<T>,
  ): Task<T> {
    return {
      id,
      create,
      state: TaskState.NOT_STARTED,
    }
  }
}

export interface TaskResult<T> {
  id: string
  result?: T
  error?: Error
  settledResult?: TaskSettledResult
}

// Create task error that extends Error
export class TaskError extends Error {
  public taskId: string
  constructor(taskId: string, message: string) {
    super(`Task ${taskId} failed: ${message}`)
    this.taskId = taskId
  }
}

export interface CancellablePromise<T> extends Promise<T> {
  cancel?: () => void
}

export class ControlledConcurrency<T> {
  private tasks: Task<T>[] = []
  private concurrency: number
  private maxErrors: number
  private runningTasks: Map<string, CancellablePromise<[string, T]>> = new Map()

  private constructor(tasks: Task<T>[], concurrency = 3, maxErrors = Infinity) {
    this.concurrency = concurrency
    this.tasks = tasks
    this.maxErrors = maxErrors
  }

  static create<T>(
    promiseCreators: { (): CancellablePromise<T> }[],
    concurrency = 3,
    maxErrors = Infinity,
  ): ControlledConcurrency<T> {
    const tasks = promiseCreators.map((creator) => {
      return TaskFactory.create(creator)
    })
    return new ControlledConcurrency(tasks, concurrency, maxErrors)
  }

  static createWithIds<T>(
    promiseCreators: { id: string; create: () => CancellablePromise<T> }[],
    concurrency = 3,
    maxErrors = Infinity,
  ): ControlledConcurrency<T> {
    const tasks = promiseCreators.map(({ id, create }) => {
      return TaskFactory.createIdentifiableTask(id, create)
    })
    return new ControlledConcurrency(tasks, concurrency, maxErrors)
  }

  private async clearSlot() {
    if (this.runningTasks.size === 0) {
      return
    }

    await Promise.race(this.runningTasks.values())
      .then((result) => {
        const [taskId, value] = result
        this.runningTasks.delete(taskId)
        return value
      })
      .catch((error) => {
        if (error instanceof TaskError) {
          this.runningTasks.delete(error.taskId)
          // Check if we've hit the error limit
          const errorCount = this.tasks.filter(
            (task) => task.settledResult === TaskSettledResult.REJECTED,
          ).length
          if (errorCount >= this.maxErrors) {
            this.cancelOutstandingTasks()
          }
        } else {
          // If the error is not a TaskError, we don't know what to do with it
          throw error
        }
      })
  }

  private startTask(
    task: Task<T>,
    onResult?: (result: T) => void,
    onError?: (error: Error) => void,
    onSettled?: (task: TaskResult<T>) => void,
  ) {
    task.state = TaskState.STARTED
    const promise: CancellablePromise<[string, T]> = task
      .create()
      .then((result) => {
        task.settledResult = TaskSettledResult.FULFILLED
        task.result = result
        onResult?.(result)
        return [task.id, result] as [string, T]
      })
      .catch((error) => {
        task.settledResult = TaskSettledResult.REJECTED
        task.error = error
        onError?.(error)
        throw new TaskError(task.id, error.message)
      })
      .finally(() => {
        task.state = TaskState.SETTLED
        onSettled?.({
          id: task.id,
          result: task.result,
          error: task.error,
          settledResult: task.settledResult,
        })
      }) as CancellablePromise<[string, T]>

    return promise
  }

  async run(
    onResult?: (result: T) => void,
    onError?: (error: Error) => void,
    onSettled?: (task: TaskResult<T>) => void,
  ) {
    // Start tasks up to the concurrency limit and
    // add them to our runningTasks map
    this.tasks
      .filter((task) => task.state === TaskState.NOT_STARTED)
      .slice(0, this.concurrency)
      .map((task) => {
        const p = this.startTask(task)
        this.runningTasks.set(task.id, p)
      })

    // Keep adding tasks as slots clear
    await this.clearSlot()
    while (this.tasks.some((task) => task.state === TaskState.NOT_STARTED)) {
      const nextTask = this.tasks.find(
        (task) => task.state === TaskState.NOT_STARTED,
      )
      if (nextTask) {
        const promise = this.startTask(nextTask, onResult, onError, onSettled)
        this.runningTasks.set(nextTask.id, promise)
      }

      await this.clearSlot()
    }

    // Clear any remaining tasks in our runningTasks
    while (this.runningTasks.size > 0) {
      await this.clearSlot()
    }
  }

  getResults(): TaskResult<T>[] {
    return this.tasks.map((task) => ({
      id: task.id,
      result: task.result,
      error: task.error,
      settledResult: task.settledResult,
    }))
  }

  async retryFailedOrCancelledTasks() {
    // Reset all tasks that failed or were cancelled to their initial state
    this.tasks
      .filter(
        (task) =>
          task.settledResult === TaskSettledResult.REJECTED ||
          task.settledResult === TaskSettledResult.CANCELLED,
      )
      .map((task) => {
        task.state = TaskState.NOT_STARTED
        task.settledResult = undefined
        task.result = undefined
        task.error = undefined
      })

    await this.run()
  }

  cancelOutstandingTasks() {
    // Cancel all tasks that are not yet started
    this.tasks
      .filter((task) => task.state === TaskState.NOT_STARTED)
      .map((task) => {
        task.state = TaskState.SETTLED
        task.settledResult = TaskSettledResult.CANCELLED
      })

    // Cancel all tasks that are currently running, attempting
    // to stop the promise in flight if possible
    this.runningTasks.forEach((promise, taskId) => {
      if (promise.cancel) {
        promise.cancel()
      }
      this.runningTasks.delete(taskId)
      const task = this.tasks.find((task) => task.id === taskId)
      if (task) {
        task.state = TaskState.SETTLED
        task.settledResult = TaskSettledResult.CANCELLED
      }
    })
  }
}

import React from 'react'

import { formatBits } from '../../lib/utils'

type MultiFileSelectionProps = {
  files: File[]
}

const MultiFileSelection: React.FC<MultiFileSelectionProps> = ({ files }) => {
  const totalSize = files.reduce((acc, file) => acc + file.size, 0)

  if (files.length === 0) {
    return null
  }

  return (
    <div className="mb-4">
      <p>
        {files.length} file{files.length !== 1 ? 's' : ''} selected - Total
        size: {formatBits(totalSize)}
      </p>
    </div>
  )
}

export default MultiFileSelection

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AssetSetType } from '@droidmap/asset-service-contract'

import { Button } from '../../components/Button'
import Input from '../../components/Input'
import MultiFileSelect from '../../components/MultiFileSelect'
import MultiFileSelection from '../../components/MultiFileSelection'
import MultiFileUploadProgress from '../../components/MultiFileUploadProgress'
import Select from '../../components/Select'
import { useAuth } from '../../context/AuthContext'
import { useAssetUpload } from '../../hooks/useAssetUpload'
import { useFormData } from '../../hooks/useFormData'

export default function AssetSetCreate() {
  const auth = useAuth()
  const navigate = useNavigate()
  const [isUploading, setIsUploading] = useState(false)

  const [createState, handleCreateChange, resetCreateForm] = useFormData({
    assetSetName: '',
    assetSetType: AssetSetType.DroneImages,
  })

  const { filesWithProgress, handleFileChange, handleUpload } = useAssetUpload({
    name: createState.assetSetName,
    projectId: auth.activeProject.id,
    assetSetType: createState.assetSetType as AssetSetType,
  })

  const hasFiles = filesWithProgress.length > 0
  const isUploadComplete = filesWithProgress.every(
    (f) => f.status === 'completed' || f.status === 'error',
  )

  const handleCreateSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsUploading(true)
    await handleUpload()
  }

  const handleCancel = () => {
    setIsUploading(false)
    resetCreateForm()
  }

  const handleClose = () => {
    navigate(`/projects/${auth.activeProject.id}`)
  }

  if (isUploading) {
    return (
      <div className="space-y-4">
        <h2 className="text-lg font-medium">
          Uploading files for "{createState.assetSetName}"
        </h2>
        <MultiFileUploadProgress filesWithProgress={filesWithProgress} />
        <div className="flex justify-end">
          {isUploadComplete ? (
            <Button onClick={handleClose} variant="creative">
              Close
            </Button>
          ) : (
            <Button onClick={handleCancel} variant="secondary">
              Cancel
            </Button>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="mb-8 space-y-2">
      <form onSubmit={resetCreateForm} className="space-y-6">
        <div className="space-y-2">
          <Input
            id="assetSetName"
            type="text"
            name="assetSetName"
            label="Asset Set Name"
            value={createState.assetSetName}
            onChange={handleCreateChange}
            placeholder="Enter asset set name"
          />
        </div>
        <div className="space-y-2">
          <Select
            id="assetSetType"
            name="assetSetType"
            label="Asset Type"
            value={createState.assetSetType}
            onChange={handleCreateChange}
          >
            <option value={AssetSetType.DroneImages}>Drone Images</option>
          </Select>
        </div>
      </form>

      <div className="pt-3 space-y-2">
        <label className="text-sm">File Selection</label>
        {!hasFiles ? (
          <MultiFileSelect onFileChange={handleFileChange} />
        ) : (
          <MultiFileSelection files={filesWithProgress.map((f) => f.file)} />
        )}
        <div className="pt-4 flex justify-end gap-2">
          <Button
            type="button"
            variant="secondary"
            onClick={() => navigate(`/projects/${auth.activeProject.id}`)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateSubmit}
            disabled={
              filesWithProgress.length === 0 || !createState.assetSetName
            }
            variant="creative"
          >
            Create Asset Set
          </Button>
        </div>
      </div>
    </div>
  )
}

import React from 'react'

import { Button } from '../Button'

type MultiFileSelectProps = {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const MultiFileSelect: React.FC<MultiFileSelectProps> = ({ onFileChange }) => {
  return (
    <div className="space-y-2">
      <input
        type="file"
        id="fileInput"
        multiple
        onChange={onFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
        <Button onClick={() => document.getElementById('fileInput')?.click()}>
          Select Files
        </Button>
      </label>
    </div>
  )
}

export default MultiFileSelect

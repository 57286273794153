import { FC, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import useScrollbarStyle from '../../hooks/useScrollbarStyle'
import { Button } from '../Button'
import DetailCard from '../DetailCard'
import FlyoutMenu from '../FlyoutMenu'

interface CardGridProps {
  title?: string
  description?: string
  data: Array<{
    id: string
    title: string
    image: string
    details: string[]
    link: string
    actions?: React.ReactNode[]
  }>
  onCreate?: () => void
  onView?: (id: string) => void
}

const CardGrid: FC<CardGridProps> = ({
  title,
  description,
  onCreate,
  onView,
  data,
}) => {
  // Can extend this functionality in future
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCard, setSelectedCard] = useState<string | null>(null)
  // Dynamically add padding to the right of the scroll container when the scrollbar appears
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  useScrollbarStyle(scrollContainerRef, 'pr-4')

  const handleCreate = () => {
    if (onCreate) {
      onCreate()
    }
  }

  const handleView = (id: string) => {
    if (onView) {
      onView(id)
    }
    setSelectedCard(null)
  }

  return (
    <div className="h-full pb-20">
      <div className="bg-surface mb-2 flex justify-between rounded-md p-2 shadow-md">
        <div className="sm:flex-auto">
          {title && (
            <h1 className="text-on-surface text-base font-semibold leading-6">
              {title}
            </h1>
          )}
          {description && (
            <p className="text-on-surface mb-2 mt-2 text-sm">{description}</p>
          )}
        </div>

        {onCreate && (
          <div className="mb-2 mt-4 self-end sm:ml-16 sm:mt-0">
            <Button onClick={handleCreate}>Create</Button>
          </div>
        )}
      </div>
      <div
        ref={scrollContainerRef}
        className="scroll-container bg-surface p-4 grid h-full grid-cols-2 gap-x-4 gap-y-8 overflow-y-auto rounded-md sm:grid-cols-3 sm:gap-x-6 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-8 xl:gap-x-8 2xl:grid-cols-5"
      >
        {data.map((item) => (
          <div className="flex flex-col relative" key={item.id}>
            <Link to={item.link} key={item.id}>
              <DetailCard
                key={item.id}
                title={item.title}
                image={item.image}
                details={item.details}
                badge="New"
                imageAlt={item.title}
                onClick={() => handleView(item.id)}
              />
            </Link>
            {item.actions && (
              <div className="absolute right-2 bottom-2 mt-2">
                <FlyoutMenu>{item.actions}</FlyoutMenu>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CardGrid

import { useQuery } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import {
  ReactCompareSlider,
  ReactCompareSliderHandle,
} from 'react-compare-slider'
import { ViewState, ViewStateChangeEvent } from 'react-map-gl/maplibre'
import { useSearchParams } from 'react-router-dom'

import { AssetWithPresignedUrl } from '@droidmap/asset-service-contract'

import { useClient } from '../../../context/ClientContext'
import { cn } from '../../../lib/utils'
import { Button } from '../../Button'
import Input from '../../Input'
import Map from '../../Map'
import { createSyncedInitialViewState } from '../../Map/util'
import Select from '../../Select'
import Spinner from '../../Spinner'
import './styles.css'

type CompareWrapperProps = {
  variant?: string
  itemOne: React.ReactNode
  itemTwo: React.ReactNode
}

const CompareWrapper = ({
  itemOne,
  itemTwo,
  variant = 'overlay',
}: CompareWrapperProps & { opacity?: number }) => {
  return variant === 'compare' ? (
    <ReactCompareSlider
      onlyHandleDraggable
      className="h-full"
      itemOne={itemOne}
      itemTwo={itemTwo}
      position={80}
      boundsPadding={40}
      handle={<ReactCompareSliderHandle />}
    />
  ) : (
    <div className="h-full flex">
      {itemOne}
      {itemTwo}
    </div>
  )
}

type MapCompareItemProps = {
  asset: AssetWithPresignedUrl
  initialViewState: Partial<ViewState>
  className?: string
  onMove: (evt: ViewStateChangeEvent) => void
  isSyncEnabled?: boolean
  opacity?: number
} & Partial<ViewState>

const VARIANTS = [
  { label: 'Compare', value: 'compare' },
  { label: 'Overlay', value: 'overlay' },
  { label: 'Side by side', value: 'side-by-side' },
] as const

const MapCompareItem = ({
  asset,
  initialViewState,
  onMove,
  opacity = 1,
  className,
  ...viewState
}: MapCompareItemProps) => (
  <div className={className} style={{ opacity }}>
    <div className="h-full flex flex-col">
      <Map
        assetId={asset.id}
        initialViewState={initialViewState}
        onMove={onMove}
        {...viewState}
      />
      <div className="w-full p-3 bg-surface">
        {asset.name} ({asset.id})
      </div>
    </div>
  </div>
)

type MapCompareProps = {
  left: AssetWithPresignedUrl
  right: AssetWithPresignedUrl
  variant?: string
}

/**
 * Component for comparing two map assets side-by-side or in an overlay mode.
 *
 * ```
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.left - The left map asset.
 * @param {Object} props.right - The right map asset.
 * @param {string} [props.variant='overlay'] - The display style for the comparison, either 'overlay' or 'side-by-side'.
 *
 * @returns {JSX.Element} The rendered component.
 * ```
 */
const MapCompare = ({ left, right, variant = 'compare' }: MapCompareProps) => {
  const [, setSearchParams] = useSearchParams()

  const [displayStyle, setDisplayStyle] = useState(variant)
  const [syncMaps, setSyncMaps] = useState(true)
  const [opacity, setOpacity] = useState(1) // Add state for opacity
  const { tileClient } = useClient()
  const [leftViewState, setLeftViewState] = useState<ViewState | undefined>()
  const [rightViewState, setRightViewState] = useState<ViewState | undefined>()

  const isSyncEnabled = syncMaps || displayStyle === 'overlay'

  const onMoveLeft = useCallback(
    ({ viewState }: ViewStateChangeEvent) => {
      setLeftViewState(viewState)
      if (isSyncEnabled) {
        setRightViewState(viewState)
      }
    },
    [isSyncEnabled],
  )

  const onMoveRight = useCallback(
    ({ viewState }: ViewStateChangeEvent) => {
      setRightViewState(viewState)
      if (isSyncEnabled) {
        setLeftViewState(viewState)
      }
    },
    [isSyncEnabled],
  )

  const cogInfoQueryLeft = useQuery({
    queryKey: ['cogInfo', left],
    queryFn: () => {
      return tileClient.getCogInfo({ assetId: left.id })
    },
  })

  const cogInfoQueryRight = useQuery({
    queryKey: ['cogInfo', right],
    queryFn: () => {
      return tileClient.getCogInfo({ assetId: right.id })
    },
  })

  // We need information about the COGs we are loading so that we can synchronize
  // position and zoom level between the two maps.

  if (cogInfoQueryLeft.isLoading || cogInfoQueryRight.isLoading) {
    return (
      <div className="h-full">
        <Spinner size="page" className="text-primary" />
      </div>
    )
  }

  if (!cogInfoQueryLeft.data || !cogInfoQueryRight.data) {
    return <div>Unable to fetch map metadata.</div>
  }

  const initialViewState = createSyncedInitialViewState(
    { width: 1000, height: 1000 },
    {
      a: cogInfoQueryLeft.data.geographic_bounds,
      b: cogInfoQueryRight.data.geographic_bounds,
    },
    40,
  )

  const commonStyles = {
    'absolute inset-0': ['compare', 'overlay'].includes(displayStyle),
  }

  return (
    <div className="h-full relative">
      <div className="absolute z-30 ml-2 flex space-x-2 items-stretch">
        <div className="flex">
          <Select
            className="w-auto"
            name="variant"
            value={displayStyle}
            onChange={(value) => {
              setSearchParams({ variant: value.target.value })
              setDisplayStyle(value.target.value)
              setOpacity(1)

              // Reset view state sync when switching display modes
              if (value.target.value === 'compare') {
                setSyncMaps(true)
              }
            }}
          >
            {VARIANTS.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </div>

        {displayStyle === 'side-by-side' && (
          <div className="flex">
            <Button
              className="w-24 absolute bottom-0"
              variant={syncMaps ? 'default' : 'secondary'}
              size="sm"
              onClick={() => setSyncMaps(!syncMaps)}
            >
              {syncMaps ? 'Sync' : 'Focused'}
            </Button>
          </div>
        )}

        {displayStyle === 'overlay' && (
          <div className="flex bg-surface rounded-md items-center mt-2 pr-3">
            <div className="flex space-x-2 items-center">
              <label
                htmlFor="opacity-slider"
                className="text-sm leading-6 text-on-surface ml-3"
              >
                Opacity
              </label>
              <Input
                id="opacity-slider"
                type="range"
                min="0"
                max="1"
                step="0.05"
                value={opacity}
                onChange={(e) => setOpacity(Number(e.target.value))}
                className="ring-0 focus:ring-0 outline-none"
              />
            </div>
          </div>
        )}
      </div>

      <CompareWrapper
        variant={displayStyle}
        itemOne={
          <MapCompareItem
            asset={left}
            initialViewState={initialViewState}
            onMove={onMoveLeft}
            opacity={opacity}
            className={cn({
              ...commonStyles,
              'w-1/2 border-r-2 border-surface':
                displayStyle === 'side-by-side',
              'z-20': displayStyle === 'overlay',
            })}
            isSyncEnabled={syncMaps || displayStyle === 'compare'}
            {...leftViewState}
          />
        }
        itemTwo={
          <MapCompareItem
            asset={right}
            initialViewState={initialViewState}
            onMove={onMoveRight}
            className={cn({
              ...commonStyles,
              'w-1/2': displayStyle === 'side-by-side',
              'w-full': displayStyle === 'overlay',
              'z-10': displayStyle === 'overlay',
            })}
            isSyncEnabled={isSyncEnabled}
            {...rightViewState}
          />
        }
      />
    </div>
  )
}

export default MapCompare

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  AssetSet,
  AssetType,
  AssetWithPresignedUrl,
} from '@droidmap/asset-service-contract'

import { getAssetType, useCompare } from '../../context/CompareContext'
import { Button } from '../Button'
import AssetSelector from './AssetSelector'

const COMPARISON_TYPES = {
  [AssetType.Tiff]: { type: 'map' },
}

interface CompareAssetSetsProps {
  assetSets: (AssetSet | undefined)[]
  supportedAssetTypes?: AssetType[]
}

export const CompareAssetSets: React.FC<CompareAssetSetsProps> = ({
  assetSets = [],
  supportedAssetTypes = [],
}) => {
  const navigate = useNavigate()
  const { setSelected } = useCompare()

  const containerRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLElement | null>(null)
  const resizeHandlerRef = useRef<(() => void) | null>(null)

  const [width, setWidth] = useState<string>('100%')
  const [assets, setAssets] = useState<AssetWithPresignedUrl[]>([])
  const [assetFilter, setAssetFilter] =
    useState<AssetType[]>(supportedAssetTypes)

  /**
   * Retrieves the current container and its parent element, then updates
   * the width of the container and the padding of the parent element based on
   * the container's height and the window's inner width.
   *
   * @function
   * @name updateDimensions
   * @memberof CompareAssetSets
   */
  const updateDimensions = useCallback(() => {
    const container = containerRef.current
    const parent = container?.parentElement

    if (container && parent) {
      parentRef.current = parent
      setWidth(`${Math.min(window.innerWidth, parent.offsetWidth + 62)}px`)
      parent.style.paddingBottom = `${container.offsetHeight}px`
    }
  }, [])

  /**
   * Resets the padding-bottom style property of the parent element to '0'.
   *
   * @returns {void}
   */
  const resetParentPadding = useCallback(() => {
    parentRef.current?.style.setProperty('padding-bottom', '0')
  }, [])

  /**
   * Checks if there are at least two assets to compare. If so, it extracts
   * the first two assets from the list and determines the comparison type based on the
   * asset type of the first asset. It then navigates to the comparison page with the
   * appropriate parameters.
   *
   * @returns {void}
   */
  const handleCompare = () => {
    if (assets.length < 2) return
    const [left, right] = assets
    const comparisonType =
      COMPARISON_TYPES[left.assetType as keyof typeof COMPARISON_TYPES]
    navigate(`/compare/${left.id}/${right.id}/${comparisonType.type}`)
  }

  const onChangeHandler = useCallback((asset: AssetWithPresignedUrl) => {
    const newAssetType = getAssetType(asset)

    setAssets((prev) => [...prev, asset])
    setAssetFilter((prev) =>
      prev.includes(newAssetType) ? prev : [...prev, newAssetType],
    )
  }, [])

  const onDeleteHandler = useCallback(
    (assetSet: AssetSet) => {
      setSelected(assetSet)
    },
    [setSelected],
  )

  useEffect(() => {
    if (assetSets.length === 0) {
      resetParentPadding()
    } else {
      updateDimensions()
    }
  }, [assetSets.length, updateDimensions, resetParentPadding])

  useEffect(() => {
    resizeHandlerRef.current = () => {
      if (assetSets.length > 0) updateDimensions()
    }

    window.addEventListener('resize', resizeHandlerRef.current)
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.removeEventListener('resize', resizeHandlerRef.current!)
      resetParentPadding()
    }
  }, [assetSets, updateDimensions, resetParentPadding])

  if (!assetSets.length) return null

  return (
    <div
      ref={containerRef}
      className="fixed bottom-0 right-0 border-accent bg-surface border-t rounded-t-lg shadow-lg z-10"
      style={{ width }}
    >
      <div className="m-6">
        <h2 className="text-lg font-bold mb-4">Compare Asset Sets</h2>
        <div className="flex flex-col md:flex-row items-center gap-3 w-full mb-4">
          <div className="flex-1 flex flex-col md:flex-row gap-4 w-full">
            {['Left', 'Right'].map((label, index) => (
              <div className="flex-1 relative" key={label}>
                <AssetSelector
                  label={label}
                  assetSet={assetSets[index]}
                  filter={assetFilter}
                  onChange={onChangeHandler}
                  onDelete={() =>
                    assetSets[index] && onDeleteHandler(assetSets[index])
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <Button
          onClick={handleCompare}
          disabled={assets.length < 2}
          className="w-full"
        >
          Compare
        </Button>
      </div>
    </div>
  )
}

import { useQuery } from '@tanstack/react-query'

import {
  AssetSet,
  AssetType,
  AssetWithPresignedUrl,
  PresignedUrlType,
} from '@droidmap/asset-service-contract'

import { useClient } from '../../../context/ClientContext'
import { getAssetType } from '../../../context/CompareContext'
import { Button } from '../../Button'
import Select from '../../Select'
import Spinner from '../../Spinner'

type AssetSelectorProps = {
  label?: string
  className?: string
  assetSet?: AssetSet
  filter?: string[]
  onChange: (asset: AssetWithPresignedUrl) => void
  onDelete?: (assetSet: AssetSet) => void
}

const ASSET_TYPES = Object.values(AssetType)

/**
 * AssetSelector component allows users to select and compare assets from a given asset set.
 *
 * ```
 * @component
 * @param {Object} props - The properties object.
 * @param {string} props.label - The label to display above the asset selector.
 * @param {Object} props.assetSet - The asset set object containing assets to be compared.
 * @param {string} [props.className] - Additional class names to apply to the component.
 * @param {Array} [props.filter] - Array of asset types to filter the assets.
 * @param {Function} [props.onChange] - Callback function to handle asset selection change.
 * @param {Function} [props.onDelete] - Callback function to handle asset set deletion.
 *
 * @returns {JSX.Element} The rendered AssetSelector component.
 * ```
 */
const AssetSelector: React.FC<AssetSelectorProps> = ({
  label,
  assetSet,
  className = '',
  filter = [],
  onChange = () => {},
  onDelete,
}) => {
  const { assetClient } = useClient()

  const {
    data: assets = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['assets', assetSet?.id],
    queryFn: async () => {
      if (!assetSet?.id) return []
      const response = await assetClient.listAssetsByAssetSet({
        assetSetId: assetSet.id,
        presignedUrlType: PresignedUrlType.GET,
      })
      return response?.items || []
    },
    enabled: Boolean(assetSet),
  })

  if (!assetSet) {
    return (
      <div className="w-full h-full flex flex-col space-y-4">
        {label && <div className="font-semibold">{label}</div>}
        <div className="border-2 border-dashed border-surface-3 p-2 rounded-lg shadow-md py-8 h-full flex items-center justify-center">
          Select asset set to compare
        </div>
      </div>
    )
  }

  const validAssetTypes = filter.length ? filter : ASSET_TYPES

  const validAssets = isLoading
    ? []
    : Object.entries(
        Object.groupBy(
          assets.filter((asset) =>
            validAssetTypes.includes(getAssetType(asset)),
          ),
          ({ assetType }) => assetType,
        ),
      ).sort()

  /**
   * Handles the change event for the asset selector dropdown.
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event triggered by selecting an option in the dropdown.
   * @returns {void}
   */
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAsset = assets.find((a) => a.id === event.target.value)
    if (selectedAsset) onChange(selectedAsset)
  }

  return (
    <div key={assetSet.id} className={`${className} space-y-4`}>
      {label && <div className="font-semibold">{label}</div>}
      <div className="relative">
        <div className="p-2 truncate w-full select-none bg-surface-3">
          {assetSet.name}
        </div>
        {onDelete && (
          <div className="absolute top-0 -right-2 h-6 p-2 text-xs -mt-12">
            <Button
              size="sm"
              className="h-6 p-2 text-xs"
              onClick={() => onDelete(assetSet)}
            >
              ✕
            </Button>
          </div>
        )}
      </div>
      <div className="w-full h-10">
        {isLoading ? (
          <div className="text-on-surface/50 pt-1 text-center flex items-center justify-center space-x-2">
            <Spinner size="lg" />
            <span>Loading assets</span>
          </div>
        ) : error ? (
          <div className="text-on-surface/50 w-full pt-1 text-center">
            Failed to fetch assets
          </div>
        ) : validAssets.length === 0 ? (
          <div className="text-on-surface/50 w-full pt-1 text-center">
            No comparable assets available
          </div>
        ) : (
          <Select onChange={handleChange}>
            <option>Select asset to compare</option>
            {validAssets.map(([type, assets]) => (
              <optgroup key={type} label={type}>
                {assets.map((asset) => (
                  <option key={asset.id} value={asset.id}>
                    {asset.name}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
        )}
      </div>
    </div>
  )
}

export default AssetSelector

import { useQuery } from '@tanstack/react-query'
import * as THREE from 'three'
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

interface ModelLoaderProps {
  objUrl: string
  mtlUrls?: string[]
  textureUrlMap?: { [key: string]: string }
  onProgress?: (progress: number) => void
}

interface ModelData {
  object: THREE.Group
  materials?: MTLLoader.MaterialCreator[]
}

const loadModel = async ({
  objUrl,
  mtlUrls = [],
  textureUrlMap = {},
  onProgress,
}: ModelLoaderProps): Promise<ModelData> => {
  const objLoader = new OBJLoader()
  objLoader.setRequestHeader({ 'Access-Control-Allow-Origin': '*' })

  let materials: MTLLoader.MaterialCreator[] | undefined

  if (mtlUrls.length > 0) {
    try {
      const mtlLoader = new MTLLoader()
      mtlLoader.setRequestHeader({ 'Access-Control-Allow-Origin': '*' })

      materials = await Promise.all(
        mtlUrls.map(
          (mtlUrl) =>
            new Promise<MTLLoader.MaterialCreator>((resolve, reject) => {
              mtlLoader.load(
                mtlUrl,
                (materials) => {
                  const originalLoadTexture =
                    materials.loadTexture.bind(materials)
                  materials.loadTexture = function (
                    path: string,
                    mapping,
                    ...args
                  ): THREE.Texture {
                    const filename = path.split('/').pop() || path
                    const presignedUrl = textureUrlMap[filename]
                    return presignedUrl
                      ? originalLoadTexture(presignedUrl, mapping, ...args)
                      : originalLoadTexture(path, mapping, ...args)
                  }
                  resolve(materials)
                },
                undefined,
                reject,
              )
            }),
        ),
      )

      materials.forEach((material) => material.preload())
      const mergedMaterials = materials.reduce((merged, material) => {
        Object.assign(merged.materials, material.materials)
        return merged
      }, materials[0])

      objLoader.setMaterials(mergedMaterials)
    } catch (error) {
      console.warn('Failed to load MTL files:', error)
    }
  }

  const object = await new Promise<THREE.Group>((resolve, reject) => {
    objLoader.load(
      objUrl,
      (object) => {
        const boundingBox = new THREE.Box3().setFromObject(object)
        const size = boundingBox.getSize(new THREE.Vector3())
        const maxDim = Math.max(size.x, size.y, size.z)
        const scale = 2 / maxDim
        object.scale.multiplyScalar(scale)
        object.rotation.x = (3 * Math.PI) / 2
        resolve(object)
      },
      (xhr) => {
        const progress = (xhr.loaded / xhr.total) * 100
        onProgress?.(Math.round(progress))
      },
      reject,
    )
  })

  return { object, materials }
}

export function useModelLoader({
  objUrl,
  mtlUrls,
  textureUrlMap,
  onProgress,
}: ModelLoaderProps) {
  return useQuery({
    queryKey: ['model', objUrl, mtlUrls, textureUrlMap],
    queryFn: () => loadModel({ objUrl, mtlUrls, textureUrlMap, onProgress }),
    staleTime: Infinity, // Keep the model cached indefinitely
    gcTime: 30 * 60 * 1000, // Cache for 30 minutes
  })
}

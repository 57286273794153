import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'

import {
  AssetSet,
  AssetSetType,
  AssetType,
  AssetWithPresignedUrl,
  CreateAssetInput,
  CreateAssetSetInput,
} from '@droidmap/asset-service-contract'
import {
  CancellablePromise,
  ControlledConcurrency,
} from '@droidmap/shared/utils'

import { useClient } from '../context/ClientContext'
import uploadFileWithProgress from '../lib/fileUpload'

export type FileWithProgress = {
  file: File
  progress: number
  rate: number
  status: 'pending' | 'uploading' | 'completed' | 'error' | 'cancelled'
}

type UseAssetUploadOptions = {
  name: string
  projectId: string
  assetSetType: AssetSetType
}

type UseAssetUploadResult = {
  filesWithProgress: FileWithProgress[]
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleUpload: () => Promise<void>
  assetSet: AssetSet | null
}

/**
 * Custom hook for asset upload.
 *
 * @param options - The options for asset upload.
 * @returns An object containing the files with progress, handleFileChange function, handleUpload function, and the created asset set.
 */
export const useAssetUpload = (
  options: UseAssetUploadOptions,
): UseAssetUploadResult => {
  const MAX_CONCURRENCY = 10
  const MAX_ERRORS = 10

  // const [concurrencyController, setConcurrencyController] =
  //   useState<ControlledConcurrency<AssetWithPresignedUrl> | null>(null)
  const [filesWithProgress, setFilesWithProgress] = useState<
    FileWithProgress[]
  >([])
  const [assetSet, setAssetSet] = useState<AssetSet | null>(null)
  const queryClient = useQueryClient()
  const { assetClient } = useClient()

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const fileArray = Array.from(event.target.files).map((file) => ({
          file,
          progress: 0,
          rate: 0,
          status: 'pending',
        }))
        setFilesWithProgress(fileArray as FileWithProgress[])
      }
    },
    [],
  )

  const assetSetMutation = useMutation({
    mutationFn: async (createAssetSetInput: CreateAssetSetInput) => {
      return await assetClient.createAssetSet(createAssetSetInput)
    },
    onSuccess: (data) => {
      setAssetSet(data)
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['assetSets', options.projectId],
      })
    },
  })

  const assetMutation = useMutation({
    mutationFn: async (assetMutationInput: {
      createAssetInput: CreateAssetInput
      uploadOptions: {
        fileWithProgress: FileWithProgress
        updateProgress: (
          percentage: number,
          rate: number,
          status: string,
        ) => void
      }
    }) => {
      const { fileWithProgress, updateProgress } =
        assetMutationInput.uploadOptions
      const asset = await assetClient.createAsset(
        assetMutationInput.createAssetInput,
      )
      console.debug('Starting file upload', fileWithProgress.file.name)
      await uploadFileWithProgress(
        asset.presignedUrl,
        fileWithProgress.file,
        updateProgress,
      )
      return asset
    },
    onSuccess: (data) => {
      console.debug('Asset uploaded', data?.id, data?.name)
    },
    onError: (error) => {
      console.error('Asset upload failed', error)
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['assets', assetSetMutation.data?.id],
      })
    },
  })

  useEffect(() => {
    if (
      filesWithProgress.length > 0 &&
      filesWithProgress.every((file) => file.status === 'completed')
    ) {
      console.log('All files completed')
    }
  }, [filesWithProgress])

  const handleUpload = useCallback(async () => {
    const createAssetSetInput: CreateAssetSetInput = {
      name: options.name,
      projectId: options.projectId,
      assetSetType: options.assetSetType,
    }

    assetSetMutation.mutate(createAssetSetInput, {
      async onSuccess(assetSet) {
        console.log('Created asset set', assetSet.id, assetSet.name)

        const assetPromises = filesWithProgress.map(
          (fileWithProgress, index) => {
            setFilesWithProgress((prevFiles) => {
              const newFiles = [...prevFiles]
              newFiles[index].status = 'pending'
              return newFiles
            })

            const updateProgress = (
              percentage: number,
              rate: number,
              status: string,
            ) => {
              setFilesWithProgress((prevFiles) => {
                const newFiles = [...prevFiles]
                newFiles[index].progress = percentage
                newFiles[index].rate = rate
                newFiles[index].status = status as FileWithProgress['status']
                return newFiles
              })
            }

            const createAssetInput: CreateAssetInput = {
              name: fileWithProgress.file.name,
              assetSetId: assetSet.id,
              //TODO - get the correct asset type from the file
              assetType: AssetType.Jpeg,
            }

            return {
              id: fileWithProgress.file.name,
              create: () =>
                assetMutation.mutateAsync({
                  createAssetInput,
                  uploadOptions: {
                    fileWithProgress,
                    updateProgress,
                  },
                }),
            }
          },
        )

        // Temp fix for the type error, will be coming back to make these
        // proper cancellable axios request promises
        const assetPromisesWithIds = assetPromises as {
          id: string
          create: () => CancellablePromise<AssetWithPresignedUrl>
        }[]
        const concurrencyController =
          ControlledConcurrency.createWithIds<AssetWithPresignedUrl>(
            assetPromisesWithIds,
            MAX_CONCURRENCY,
            MAX_ERRORS,
          )

        await concurrencyController.run()
      },
    })
  }, [assetMutation, assetSetMutation, filesWithProgress, options])

  return {
    filesWithProgress,
    handleFileChange,
    handleUpload,
    assetSet,
  }
}

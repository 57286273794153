import { UseQueryResult, useQueries } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'

import {
  AssetServiceClient,
  AssetSet,
  AssetWithPresignedUrl,
} from '@droidmap/asset-service-contract'

import MapCompare from '../../components/CompareAssetSets/Map'
import PageHeader from '../../components/PageHeader'
import Spinner from '../../components/Spinner'
import { useClient } from '../../context/ClientContext'

type AssetCompare = {
  asset: AssetWithPresignedUrl
  assetSet: AssetSet
}

const components = {
  map: MapCompare,
}

const fetchAsset = async (assetId: string, assetClient: AssetServiceClient) => {
  const asset = await assetClient.getAsset({ assetId })
  const assetSet = await assetClient.getAssetSet({
    assetSetId: asset.assetSetId,
  })

  return { asset, assetSet }
}

const ComparePage = () => {
  const { left, right, type } = useParams()
  const [searchParams] = useSearchParams()
  const { assetClient } = useClient()

  if (!left) throw new Error('Left asset ID not provided')
  if (!right) throw new Error('Right asset ID not provided')

  const Compare = components[type as keyof typeof components]

  if (!Compare) throw new Error('No valid comparison type provided')

  const results = useQueries({
    queries: [
      {
        queryKey: ['compare', left, assetClient],
        queryFn: () => fetchAsset(left, assetClient),
      },
      {
        queryKey: ['compare', right, assetClient],
        queryFn: () => fetchAsset(right, assetClient),
      },
    ],
  }) as UseQueryResult<AssetCompare, Error>[]

  const isLoading = results.some((result) => result.isLoading)

  if (isLoading) {
    return (
      <div>
        <Spinner size="page" className="text-primary" />
      </div>
    )
  }

  const [
    { asset: leftAsset, assetSet: leftAssetSet },
    { asset: rightAsset, assetSet: rightAssetSet },
  ] = results.map((result) => result.data) as AssetCompare[]

  const subTitle = `Asset Sets: ${leftAssetSet?.id} vs. ${rightAssetSet?.id}`

  return (
    <div className="flex flex-col h-full space-y-4">
      <PageHeader title="Compare" subtitle={subTitle} />
      <div className="flex-1">
        <Compare
          left={leftAsset}
          right={rightAsset}
          variant={searchParams.get('variant') ?? undefined}
        />
      </div>
    </div>
  )
}

export default ComparePage

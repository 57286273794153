import { Billboard, Html, Line } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import React, { useState } from 'react'
import * as THREE from 'three'

interface MeasurementToolProps {
  active: boolean
}

export const MeasurementTool: React.FC<MeasurementToolProps> = ({ active }) => {
  const [startPoint, setStartPoint] = useState<THREE.Vector3 | null>(null)
  const [endPoint, setEndPoint] = useState<THREE.Vector3 | null>(null)
  const [isDrawing, setIsDrawing] = useState(false)
  const { camera, scene, raycaster, gl } = useThree()

  const getIntersectionPoint = (event: PointerEvent): THREE.Vector3 | null => {
    const rect = gl.domElement.getBoundingClientRect()
    const pointer = new THREE.Vector2(
      ((event.clientX - rect.left) / rect.width) * 2 - 1,
      -((event.clientY - rect.top) / rect.height) * 2 + 1,
    )
    raycaster.setFromCamera(pointer, camera)

    // Filter the scene to only include meshes (the actual 3D model geometry)
    const meshes: THREE.Mesh[] = []
    scene.children.forEach((child: THREE.Object3D) => {
      if (child instanceof THREE.Group) {
        child.traverse((object) => {
          if (object instanceof THREE.Mesh) {
            meshes.push(object)
          }
        })
      }
    })

    const intersects = raycaster.intersectObjects(meshes, false)
    return intersects.length > 0 ? intersects[0].point.clone() : null
  }

  const handlePointerDown = (event: PointerEvent) => {
    if (!active) return

    const point = getIntersectionPoint(event)
    if (point) {
      if (!startPoint) {
        setStartPoint(point)
        setIsDrawing(true)
      } else {
        setEndPoint(point)
        setIsDrawing(false)
      }
    }
  }

  const handlePointerMove = (event: PointerEvent) => {
    if (!active || !isDrawing || !startPoint) return

    const point = getIntersectionPoint(event)
    if (point) {
      setEndPoint(point)
    }
  }

  React.useEffect(() => {
    if (active) {
      gl.domElement.addEventListener('pointerdown', handlePointerDown)
      gl.domElement.addEventListener('pointermove', handlePointerMove)
      return () => {
        gl.domElement.removeEventListener('pointerdown', handlePointerDown)
        gl.domElement.removeEventListener('pointermove', handlePointerMove)
      }
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, startPoint, isDrawing, gl, camera, scene, raycaster])

  React.useEffect(() => {
    if (!active) {
      setStartPoint(null)
      setEndPoint(null)
      setIsDrawing(false)
    }
  }, [active])

  if (!startPoint) return null

  const currentEndPoint = endPoint || startPoint
  const distance = startPoint.distanceTo(currentEndPoint)
  const midPoint = startPoint.clone().add(currentEndPoint).multiplyScalar(0.5)

  return (
    <group>
      <Line
        points={[startPoint, currentEndPoint]}
        color="yellow"
        lineWidth={2}
      />

      {/* Start point marker */}
      <Billboard position={startPoint} renderOrder={1}>
        <mesh>
          <sphereGeometry args={[0.01]} />
          <meshBasicMaterial color="yellow" depthTest={false} />
        </mesh>
      </Billboard>

      {/* End point marker */}
      <Billboard position={currentEndPoint} renderOrder={1}>
        <mesh>
          <sphereGeometry args={[0.01]} />
          <meshBasicMaterial color="yellow" depthTest={false} />
        </mesh>
      </Billboard>

      {/* Distance label */}
      <Html position={midPoint} center>
        <div className="bg-black bg-opacity-75 text-white px-2 py-1 rounded text-sm">
          {distance.toFixed(3)} units
        </div>
      </Html>
    </group>
  )
}

import { useEffect, useState } from 'react'

interface MoveState {
  forward: boolean
  backward: boolean
  left: boolean
  right: boolean
  up: boolean
  down: boolean
}

export const useKeyboardControls = () => {
  const [moveState, setMoveState] = useState<MoveState>({
    forward: false,
    backward: false,
    left: false,
    right: false,
    up: false,
    down: false,
  })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.code) {
        case 'KeyW':
          setMoveState((state) => ({ ...state, forward: true }))
          break
        case 'KeyS':
          setMoveState((state) => ({ ...state, backward: true }))
          break
        case 'KeyA':
          setMoveState((state) => ({ ...state, left: true }))
          break
        case 'KeyD':
          setMoveState((state) => ({ ...state, right: true }))
          break
        case 'Space':
          setMoveState((state) => ({ ...state, up: true }))
          break
        case 'ShiftLeft':
          setMoveState((state) => ({ ...state, down: true }))
          break
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      switch (event.code) {
        case 'KeyW':
          setMoveState((state) => ({ ...state, forward: false }))
          break
        case 'KeyS':
          setMoveState((state) => ({ ...state, backward: false }))
          break
        case 'KeyA':
          setMoveState((state) => ({ ...state, left: false }))
          break
        case 'KeyD':
          setMoveState((state) => ({ ...state, right: false }))
          break
        case 'Space':
          setMoveState((state) => ({ ...state, up: false }))
          break
        case 'ShiftLeft':
          setMoveState((state) => ({ ...state, down: false }))
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return moveState
}

import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router-dom'

import {
  AssetServiceClient,
  AssetSet,
  AssetSetType,
  AssetType,
  AssetWithPresignedUrl,
  PresignedUrlType,
} from '@droidmap/asset-service-contract'

import { SimpleAssetView, TabbedAssetView } from '../../components/AssetView'
import { Card, CardContent } from '../../components/Card'
import PageHeader from '../../components/PageHeader'
import Spinner from '../../components/Spinner'
import { useClient } from '../../context/ClientContext'

const fetchAssetSet = async (
  assetClient: AssetServiceClient,
  id: string,
): Promise<AssetSet> => {
  const response = await assetClient.getAssetSet({
    assetSetId: id,
    presignedUrlType: PresignedUrlType.GET,
  })
  if (!response) throw new Error('Failed to fetch asset set')
  return response
}

const fetchAssets = async (
  assetClient: AssetServiceClient,
  assetSetId: string,
): Promise<AssetWithPresignedUrl[]> => {
  const response = await assetClient.listAssetsByAssetSet({
    assetSetId,
    presignedUrlType: PresignedUrlType.GET,
  })
  if (!response) throw new Error('Failed to fetch assets')
  return response.items
}

const AssetSetPage = () => {
  const { id } = useParams()

  if (!id) throw new Error('No asset set ID provided')

  const { assetClient } = useClient()

  const {
    data: assetSet,
    isLoading: isLoadingSet,
    error: setError,
  } = useQuery({
    queryKey: ['assetSet', id, assetClient],
    queryFn: () => fetchAssetSet(assetClient, id),
  })

  const {
    data: assets,
    isLoading: isLoadingAssets,
    error: assetsError,
  } = useQuery({
    queryKey: ['assets', id, assetClient],
    queryFn: () => fetchAssets(assetClient, id),
  })

  if (isLoadingSet || isLoadingAssets) {
    return <Spinner size="page" className="text-primary" />
  }

  if (setError || assetsError) {
    return (
      <div className="p-4">
        <Card className="bg-status-error">
          <CardContent className="pt-6">
            <div className="flex items-center space-x-2 text-on-primary">
              <ExclamationCircleIcon className="h-5 w-5" />
              <p>Error: {setError?.message || assetsError?.message}</p>
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }

  // Group assets by type only if it's OdmOutputs
  const groupedAssets =
    assetSet?.assetSetType === AssetSetType.OdmOutputs
      ? assets?.reduce(
          (acc, asset) => {
            const group =
              asset.assetType === AssetType.Tiff ? 'mapAssets' : 'otherAssets'
            if (!acc[group]) acc[group] = []
            acc[group].push(asset)
            return acc
          },
          {} as Record<string, AssetWithPresignedUrl[]>,
        )
      : undefined

  return (
    <div>
      <PageHeader
        title={assetSet?.name || 'Asset Set'}
        subtitle={`ID: ${assetSet?.id}`}
      />

      {assetSet?.assetSetType === AssetSetType.DroneImages ? (
        <SimpleAssetView assets={assets || []} />
      ) : assetSet?.assetSetType === AssetSetType.OdmOutputs &&
        groupedAssets ? (
        <TabbedAssetView groupedAssets={groupedAssets} />
      ) : null}
    </div>
  )
}

export default AssetSetPage

import { ReactNode, createContext, useContext, useState } from 'react'

import {
  AssetSet,
  AssetSetType,
  AssetType,
  AssetWithPresignedUrl,
} from '@droidmap/asset-service-contract'

import { CompareAssetSets } from '../components/CompareAssetSets'

interface Compare {
  selected: AssetSet[]
  disabled: boolean
  setSelected: (assetSet: AssetSet) => void
  isSelected: (assetSet: AssetSet) => boolean
  isSupported: (assetSet: AssetSet) => boolean
}

const MAX_ASSETS = 2
const CompareContext = createContext<Compare | null>({
  selected: [],
  disabled: false,
  setSelected: () => {},
  isSelected: () => false,
  isSupported: () => false,
})

export const useCompare = () => {
  const context = useContext(CompareContext)
  if (!context) {
    throw new Error('useCompare must be used within a CompareProvider')
  }
  return context
}

interface CompareProviderProps {
  supportedAssetSets?: AssetSetType[]
  supportedAssetTypes?: AssetType[]
  children: ReactNode
}

export const CompareProvider: React.FC<CompareProviderProps> = ({
  supportedAssetSets = [],
  supportedAssetTypes = [],
  children,
}) => {
  const [selected, setSelected] = useState<AssetSet[]>([])

  const toggleSelection = (assetSet: AssetSet) => {
    setSelected((prev) =>
      prev.some((a) => a.id === assetSet.id)
        ? prev.filter((a) => a.id !== assetSet.id)
        : [...prev, assetSet],
    )
  }

  const isSupported = (assetSet: AssetSet) =>
    (supportedAssetSets.length === 0 ||
      supportedAssetSets.includes(assetSet.assetSetType)) &&
    (selected.length === 0 ||
      selected[0].assetSetType === assetSet.assetSetType)

  const isSelected = (assetSet: AssetSet) =>
    selected.some((a) => a.id === assetSet.id)

  return (
    <CompareContext.Provider
      value={{
        selected,
        disabled: selected.length === MAX_ASSETS,
        setSelected: toggleSelection,
        isSupported,
        isSelected,
      }}
    >
      {children}
      {selected.length > 0 && (
        <CompareAssetSets
          assetSets={selected}
          supportedAssetTypes={supportedAssetTypes}
        />
      )}
    </CompareContext.Provider>
  )
}

export const getAssetType = (asset: AssetWithPresignedUrl): AssetType =>
  asset.assetType === 'other'
    ? (asset.name.split('.').pop() as AssetType) || 'unknown'
    : asset.assetType

import { QueryParameterBag } from '@smithy/types'

import { DroidMapError, EntityAlreadyExistsError } from './error-handling'

export const toParameterString = (queryParams: QueryParameterBag): string => {
  const parts: string[] = []

  for (const [key, value] of Object.entries(queryParams)) {
    if (value === null) {
      parts.push(key)
    } else if (Array.isArray(value)) {
      for (const item of value) {
        parts.push(`${key}=${encodeURIComponent(item)}`)
      }
    } else {
      parts.push(`${key}=${encodeURIComponent(value)}`)
    }
  }

  return parts.join('&')
}

export enum HttpStatus {
  OK_200 = 200,
  CREATED_201 = 201,
  BAD_REQUEST_400 = 400,
  UNAUTHORIZED_401 = 401,
  FORBIDDEN_403 = 403,
  NOT_FOUND_404 = 404,
  CONFLICT_409 = 409,
  TOO_MANY_REQUESTS_429 = 429,
  INTERNAL_SERVER_ERROR_500 = 500,
  BAD_GATEWAY_502 = 502,
  SERVICE_UNAVAILABLE_503 = 503,
  GATEWAY_TIMEOUT_504 = 504,
}

const RetryStatusCodes = [
  HttpStatus.TOO_MANY_REQUESTS_429,
  HttpStatus.INTERNAL_SERVER_ERROR_500,
  HttpStatus.BAD_GATEWAY_502,
  HttpStatus.SERVICE_UNAVAILABLE_503,
  HttpStatus.GATEWAY_TIMEOUT_504,
]

export type RetryOptions = {
  maxRetries?: number
  initialDelay?: number
  fetchFn?: (input: Request) => Promise<Response>
}

export enum StandardHttpHeaders {
  AUTHORIZATION = 'authorization',
}

/**
 * Headers we have defined for our own use.
 */
export enum DroidMapHttpHeaders {
  X_DROIDMAP_INTERNAL = 'x-droidmap-internal',
  X_DROIDMAP_ON_BEHALF_OF = 'x-droidmap-on-behalf-of',
  X_DROIDMAP_ORG = 'x-droidmap-org',
  X_DROIDMAP_PROJECT = 'x-droidmap-project',
}

/**
 * AWS HTTP Headers
 *
 * Used to propagate distributed tracing information between services.
 * This is the standard used by AWS X-Ray.
 */
export enum AwsHttpHeaders {
  X_AMZN_TRACE_ID = 'x-amzn-trace-id',
}

/**
 * W3C Trace Context HTTP Headers
 *
 * Used to propagate distributed tracing information between services.
 * This is the standard used by OpenTelemetry.
 *
 * @see https://www.w3.org/TR/trace-context/
 */
export enum W3CTraceContextHttpHeaders {
  TRACE_PARENT = 'traceparent',
  TRACE_STATE = 'tracestate',
}

/**
 * It looks like the official implementation of fetch in the NodeJs runtime
 * has recently added a retry option, but it's not yet available in the
 * version of NodeJs we're using.
 *
 * https://github.com/nodejs/undici/pull/2281
 *
 * We may want to move over to that version when available.
 *
 * @param createRequest
 * @param maxRetries
 * @param initialDelay
 * @returns
 */
export const retryFetch = async (
  createRequest: () => Request,
  { maxRetries = 5, initialDelay = 200, fetchFn = fetch }: RetryOptions = {},
): Promise<Response> => {
  let delay = initialDelay
  // random delay multiplier between 1.8 and 2.3
  const delayMultiplier = 1.8 + Math.random() * 0.5

  for (let i = 1; i <= maxRetries; i++) {
    const request = createRequest()

    let response: Response
    try {
      response = await fetchFn(request)
    } catch (error) {
      console.error(
        JSON.stringify({
          message: 'Fetch implementation threw an error - no http response',
          attempt: i,
          fetchError: error,
          request: request.url,
          requestBody: request.body,
        }),
      )

      // Our start-odm-stage function will throw a TypeError randomly with
      // the underlying cause being other side socket closed. This only happens
      // with undici fetch in nodejs though. Experimenting with just retrying
      // on this otherwise will have to ditch undici fetch.
      if (
        error instanceof Error &&
        error.name === 'TypeError' &&
        error.message === 'fetch failed'
      ) {
        await new Promise((resolve) => setTimeout(resolve, delay))
        // Increase the delay for the next attempt
        delay *= delayMultiplier
        continue
      } else {
        throw error
      }
    }

    if (response.ok) {
      return response
    }

    // Currently we are using the 409 status code to indicate that the entity already exists
    // We can stop retrying and convert the response back to an EntityAlreadyExistsError
    if (response.status === HttpStatus.CONFLICT_409) {
      const body = await response.json()
      throw new EntityAlreadyExistsError(body.message, {
        context: body.context,
      })
    }

    // Undici fetch requires you to read the response body in order to free up
    // the connection
    const errorText = await response.text()

    // If the response is not ok, check if we can retry the request
    if (RetryStatusCodes.includes(response.status)) {
      console.error(
        JSON.stringify({
          message: `Fetch attempt failed`,
          retryAttempt: i,
          route: `${request.method} ${request.url}`,
          body: `${request.body}`,
          retryDelay: delay,
          errorText,
        }),
      )
      await new Promise((resolve) => setTimeout(resolve, delay))
      // Increase the delay for the next attempt
      delay *= delayMultiplier
      continue
    } else {
      throw new DroidMapError(
        `Failed to call api route - ${response.status} ${response.statusText}`,
        {
          context: JSON.stringify({
            responseStatus: response.status,
            responseStatusText: response.statusText,
            route: `${request.method} ${request.url}`,
            body: `${request.body}`,
            attempt: i,
            errorText,
          }),
        },
      )
    }
  }

  // If we get here, we've exceeded the max retries
  const request = createRequest()
  throw new DroidMapError(
    `Failed to call api route after the maximum number of retries`,
    {
      context: JSON.stringify({
        route: `${request.method} ${request.url}`,
        attempts: maxRetries,
        request,
      }),
    },
  )
}

import {
  CubeIcon,
  Square2StackIcon,
  TrashIcon,
} from '@heroicons/react/20/solid'
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
  AssetContentType,
  AssetSet,
  AssetSetType,
  AssetType,
} from '@droidmap/asset-service-contract'
import { CreatePhotogrammetryTaskInput } from '@droidmap/project-service-contract'
import { OdmSettings } from '@droidmap/shared/odm-settings'

import { Button } from '../../components/Button'
import CardGrid from '../../components/CardGrid'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import DataTable from '../../components/DataTable'
import Modal from '../../components/Modal'
import OdmForm from '../../components/OdmForm'
import PageHeader from '../../components/PageHeader'
import Spinner from '../../components/Spinner'
import { useClient } from '../../context/ClientContext'
import { CompareProvider, useCompare } from '../../context/CompareContext'
import { fetchAssetSets, fetchTasks } from '../../lib/clientHelpers'
import { TaskFormData, taskSchema } from '../../lib/taskFormSchema'
import { formatBytes, getDirtyValues } from '../../lib/utils'
import { columns } from './columns'

const AssetSets = ({ data = [] }: { data: AssetSet[] }) => {
  const navigate = useNavigate()
  const { disabled, setSelected, isSupported, isSelected } = useCompare()
  const queryClient = useQueryClient()
  const { assetClient } = useClient()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [assetSetToDelete, setAssetSetToDelete] = useState<AssetSet | null>(
    null,
  )

  const handleCreateAssetSet = () => {
    navigate('/asset-set/create')
  }

  const deleteMutation = useMutation({
    mutationFn: async (assetSetId: string) => {
      return await assetClient.deleteAssetSet({ assetSetId })
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['assetSets'],
      })
      setDeleteModalOpen(false)
      setAssetSetToDelete(null)
    },
  })

  const handleDelete = (assetSet: AssetSet) => {
    setAssetSetToDelete(assetSet)
    setDeleteModalOpen(true)
  }

  const confirmDelete = () => {
    if (assetSetToDelete) {
      deleteMutation.mutate(assetSetToDelete.id)
    }
  }

  const supportedAssetSets = data.filter(isSupported).length > 1
  const supportedAssetSetsWith3dModels = data.filter(
    (assetSet) =>
      isSupported(assetSet) &&
      assetSet.containedAssetTypes?.includes(AssetContentType.Model3D),
  )

  return (
    <>
      <CardGrid
        title="Asset Sets"
        description="Asset Sets you own."
        onCreate={handleCreateAssetSet}
        data={
          data.map((assetSet) => {
            const assetSize = assetSet.storageSummary.standard
            const canCompare =
              isSupported(assetSet) && supportedAssetSets && assetSize > 0
            const has3dModel = supportedAssetSetsWith3dModels.includes(assetSet)

            const actions = [
              <Button
                key={`delete-${assetSet.id}`}
                aria-label="Delete"
                onClick={() => handleDelete(assetSet)}
                variant="ghost"
                className="w-full text-red-600 hover:text-red-700"
              >
                <span className="flex items-center gap-2">
                  <span>
                    <TrashIcon className="w-5 h-5" />
                  </span>
                  Delete
                </span>
              </Button>,
            ]

            if (canCompare) {
              actions.push(
                <Button
                  key={`compare-${assetSet.id}`}
                  aria-label="Compare"
                  onClick={() => setSelected(assetSet)}
                  disabled={!isSelected(assetSet) && disabled}
                  variant="ghost"
                  className="w-full"
                >
                  <span className="flex items-center gap-2">
                    <span>
                      <Square2StackIcon className="w-5 h-5" />
                    </span>
                    Compare
                  </span>
                </Button>,
              )
            }

            if (has3dModel) {
              actions.push(
                <Button
                  key={`model-${assetSet.id}`}
                  aria-label="Model"
                  onClick={() => navigate(`/models/${assetSet.id}`)}
                  variant="ghost"
                  className="w-full"
                >
                  <span className="flex items-center gap-2">
                    <span>
                      <CubeIcon className="w-5 h-5" />
                    </span>
                    Model
                  </span>
                </Button>,
              )
            }

            return {
              title: assetSet.name,
              id: assetSet.id,
              details: [
                `Type: ${assetSet.assetSetType}`,
                `Size: ${formatBytes(assetSize)}`,
              ],
              image: 'icon_white_square.png',
              link: `/asset-sets/${assetSet.id}`,
              actions,
            }
          }) || []
        }
      />
      <ConfirmationDialog
        title="Delete Asset Set"
        description="Are you sure you want to delete this asset set? This action cannot be undone."
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false)
          setAssetSetToDelete(null)
        }}
        onConfirm={confirmDelete}
        confirmText={deleteMutation.isPending ? 'Deleting...' : 'Delete'}
        cancelText="Cancel"
        variant="destructive"
      />
    </>
  )
}

export default function Project() {
  const queryClient = useQueryClient()
  const { projectClient, assetClient } = useClient()
  const [createItemOpen, setCreateItemOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewItemOpen, setViewItemOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTaskId, setSelectedTaskId] = useState<string>('')

  const { id: projectId } = useParams<{ id: string }>()

  if (!projectId) {
    throw new Error('No project ID provided')
  }

  const form = useForm<TaskFormData>({
    resolver: zodResolver(taskSchema),
    mode: 'all',
  })

  const {
    data: project,
    isError: isProjectError,
    error: projectError,
    isLoading: isLoadingProject,
  } = useQuery({
    queryKey: ['project', projectId],
    queryFn: () => projectClient.getProject({ projectId }),
  })

  const assetSetsQuery = useQuery({
    queryKey: ['assetSets', projectId],
    queryFn: () => {
      if (!project) {
        return Promise.reject('Project data is not available yet.')
      }
      return fetchAssetSets([project], assetClient)
    },
    enabled: !!project,
  })

  const tasksQuery = useQuery({
    queryKey: ['tasks', projectId],
    queryFn: () => {
      if (!project) {
        return Promise.reject('Project data is not available yet.')
      }
      return fetchTasks([project], projectClient)
    },
    enabled: !!project,
  })

  const createMutation = useMutation({
    mutationFn: async (formData: TaskFormData) => {
      setCreateItemOpen(false)

      if (!project?.id) {
        throw new Error('Project ID is not defined')
      }

      if (!project?.orgId) {
        throw new Error('Org ID is not defined')
      }

      const validSchema = taskSchema.safeParse(formData)

      if (!validSchema.success) {
        throw new Error(validSchema.error.errors.join(', '))
      }

      const outputImagesAssetSet = await assetClient.createAssetSet({
        projectId: project.id,
        name: `${formData.droidmap.taskName} - ${formData.droidmap.outputAssetSetType}`,
        assetSetType: formData.droidmap.outputAssetSetType,
      })

      const dirtyValues = getDirtyValues(
        { ...form.formState.dirtyFields, droidmap: undefined },
        formData,
      ) as OdmSettings

      const photogrammetryTaskInput: CreatePhotogrammetryTaskInput = {
        projectId: project.id,
        name: formData.droidmap.taskName,
        type: formData.droidmap.taskType,
        inputAssetSets: [formData.droidmap.assetSetId],
        outputAssetSets: [outputImagesAssetSet.id],
        odmSettings: dirtyValues,
      }

      return await projectClient.createTask(photogrammetryTaskInput)
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['tasks', project],
      })
      form.reset()
    },
  })

  const onSubmit = (data: TaskFormData) => {
    createMutation.mutate(data)
  }

  if (isLoadingProject) {
    return <Spinner size="page" className="text-primary" />
  }

  if (isProjectError) {
    return <div>Error: {projectError?.message}</div>
  }

  const handleCreateItem = () => {
    setCreateItemOpen(true)
  }

  const handleViewTask = (taskId: string) => {
    setViewItemOpen(true)
    setSelectedTaskId(taskId)
  }

  return (
    <div>
      <PageHeader
        title={project?.name || 'Project'}
        subtitle={`ID: ${project?.id}`}
      />
      <div className="mt-6">
        <DataTable
          data={tasksQuery.data || []}
          columns={columns}
          title="Tasks"
          description="Tasks in project."
          onCreate={handleCreateItem}
          tableOptions={{
            meta: {
              onView: handleViewTask,
            },
          }}
        />
      </div>
      <div className="mt-6 pb-6">
        <CompareProvider
          supportedAssetSets={[AssetSetType.OdmOutputs]}
          supportedAssetTypes={[AssetType.Tiff]}
        >
          <AssetSets data={assetSetsQuery.data || []} />
        </CompareProvider>
      </div>
      <Modal
        title="Create Task"
        icon={<PlusCircleIcon />}
        isOpen={createItemOpen}
        onClose={() => {
          setCreateItemOpen(false)
          form.reset()
        }}
      >
        <OdmForm<TaskFormData>
          form={form}
          onSubmit={onSubmit}
          assetSets={assetSetsQuery.data || []}
        />
      </Modal>
      {/* {viewItemOpen && (
        <TaskDetailsModal
          taskId={selectedTaskId}
          onClose={() => setViewItemOpen(false)}
        />
      )} */}
    </div>
  )
}

import { useQueries, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import {
  AssetServiceClient,
  AssetWithPresignedUrl,
} from '@droidmap/asset-service-contract'

import { Button } from '../../components/Button'
import CardGrid from '../../components/CardGrid'
import Spinner from '../../components/Spinner'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { fetchAssetSets, fetchProjects } from '../../lib/clientHelpers'

// Import constants from Models page
const MODEL_FOLDER = 'odm_texturing'
const SUPPORTED_EXTENSIONS = {
  MODEL: '.obj',
  MATERIAL: '.mtl',
  TEXTURE: '.png',
} as const

const has3DModel = async (
  assetClient: AssetServiceClient,
  assetSetId: string,
) => {
  try {
    const manifest = (await assetClient.getAssetByPath({
      assetSetId,
      path: 'manifest.json',
    })) as AssetWithPresignedUrl

    if (!manifest?.presignedUrl) {
      return false
    }

    const response = await fetch(manifest.presignedUrl)
    const manifestData = await response.json()

    return manifestData.files.some(
      (file: { fileExtension: string; folderPath: string }) =>
        file.fileExtension === SUPPORTED_EXTENSIONS.MODEL &&
        file.folderPath === MODEL_FOLDER,
    )
  } catch (error) {
    console.error('Error fetching manifest:', error)
    return false
  }
}

export default function AssetSets() {
  const auth = useAuth()
  const { projectClient, assetClient } = useClient()

  const navigate = useNavigate()

  const handleCreate = () => {
    navigate('/asset-set/create')
  }

  const handleViewModel = (assetSetId: string) => {
    navigate(`/model-viewer/${assetSetId}`)
  }

  // TODO: This should be a separate query for each group id so they can be cached individually
  const { data: projects = [], isLoading: isLoadingProjects } = useQuery({
    queryKey: ['projects', auth.activeOrganisation.id, projectClient],
    queryFn: () => fetchProjects(auth.activeOrganisation.id, projectClient),
  })

  // TODO: This should be a separate query for each project id so they can be cached individually
  const { data: assetSets = [], isLoading: isLoadingAssetSets } = useQuery({
    queryKey: ['assetSets', projects],
    queryFn: () => fetchAssetSets(projects, assetClient),
    enabled: projects?.length > 0,
  })

  // Add a query for manifests
  const manifestQueries = useQueries({
    queries: assetSets.map((assetSet) => ({
      queryKey: ['manifest', assetSet.id],
      queryFn: () => has3DModel(assetClient, assetSet.id),
      enabled: !!assetSet.id,
    })),
  })

  if (
    isLoadingProjects ||
    isLoadingAssetSets ||
    manifestQueries.some((q) => q.isLoading)
  ) {
    return <Spinner size="page" className="text-primary" />
  }

  return (
    <div className="h-full">
      <CardGrid
        title="Asset Sets"
        description="Asset Sets you own."
        onCreate={handleCreate}
        data={assetSets?.map((assetSet, index) => {
          const details = [`Type: ${assetSet.assetSetType}`]

          const actions = []
          const hasModel = manifestQueries[index]?.data

          if (hasModel) {
            actions.push(
              <Button
                key="view-model"
                onClick={(e) => {
                  e.preventDefault() // Prevent Link navigation
                  handleViewModel(assetSet.id)
                }}
                className="ml-2"
                size="sm"
              >
                View 3D Model
              </Button>,
            )
          }

          return {
            title: assetSet.name,
            id: assetSet.id,
            details,
            actions,
            image: 'icon_white_square.png',
            link: `/asset-sets/${assetSet.id}`,
          }
        })}
      />
    </div>
  )
}
